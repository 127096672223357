export default `age,mu,sigma,nu
2,16.00445849,0.07284844391,-1.824646786
2.01,16.00343181,0.07287073774,-1.825172169
2.02,16.00240001,0.07289314963,-1.825700172
2.03,16.0013631,0.07291567969,-1.826230795
2.04,16.00032109,0.07293832801,-1.826764039
2.05,15.99927398,0.07296109472,-1.827299904
2.06,15.99822178,0.0729839799,-1.827838388
2.07,15.99716451,0.07300698366,-1.828379493
2.08,15.99610218,0.07303010611,-1.828923218
2.09,15.99503479,0.07305334736,-1.829469563
2.1,15.99396238,0.0730767075,-1.830018528
2.11,15.99288495,0.07310018665,-1.830570113
2.12,15.99180253,0.07312378492,-1.831124317
2.13,15.99071511,0.0731475024,-1.831681139
2.14,15.98962273,0.07317133921,-1.83224058
2.15,15.98852541,0.07319529545,-1.832802639
2.16,15.98742316,0.07321937124,-1.833367316
2.17,15.986316,0.07324356667,-1.83393461
2.18,15.98520397,0.07326788187,-1.834504522
2.19,15.98408707,0.07329231694,-1.835077051
2.2,15.98296534,0.07331687199,-1.835652197
2.21,15.9818388,0.07334154713,-1.836229959
2.22,15.98070747,0.07336634247,-1.836810337
2.23,15.97957139,0.07339125813,-1.837393331
2.24,15.97843057,0.07341629421,-1.83797894
2.25,15.97728504,0.07344145083,-1.838567163
2.26,15.97613483,0.0734667281,-1.839158001
2.27,15.97497997,0.07349212614,-1.839751452
2.28,15.97382049,0.07351764506,-1.840347516
2.29,15.9726564,0.07354328498,-1.840946193
2.3,15.97148775,0.07356904601,-1.841547482
2.31,15.97031456,0.07359492826,-1.842151382
2.32,15.96913686,0.07362093187,-1.842757892
2.33,15.96795469,0.07364705694,-1.843367013
2.34,15.96676807,0.07367330359,-1.843978743
2.35,15.96557704,0.07369967194,-1.844593082
2.36,15.96438163,0.07372616212,-1.845210028
2.37,15.96318187,0.07375277424,-1.845829582
2.38,15.9619778,0.07377950843,-1.846451742
2.39,15.96076945,0.0738063648,-1.847076507
2.4,15.95955685,0.0738333435,-1.847703876
2.41,15.95834003,0.07386044463,-1.84833385
2.42,15.95711904,0.07388766832,-1.848966426
2.43,15.95589391,0.0739150147,-1.849601604
2.44,15.95466467,0.07394248389,-1.850239383
2.45,15.95343136,0.07397007603,-1.850879762
2.46,15.95219401,0.07399779124,-1.851522739
2.47,15.95095266,0.07402562965,-1.852168315
2.48,15.94970736,0.07405359138,-1.852816487
2.49,15.94845812,0.07408167658,-1.853467255
2.5,15.947205,0.07410988537,-1.854120617
2.51,15.94594803,0.07413821789,-1.854776573
2.52,15.94468725,0.07416667426,-1.855435121
2.53,15.9434227,0.07419525463,-1.85609626
2.54,15.94215441,0.07422395913,-1.85675999
2.55,15.94088242,0.07425278789,-1.857426308
2.56,15.93960678,0.07428174106,-1.858095214
2.57,15.93832752,0.07431081876,-1.858766707
2.58,15.93704468,0.07434002115,-1.859440785
2.59,15.93575831,0.07436934836,-1.860117447
2.6,15.93446843,0.07439880053,-1.860796693
2.61,15.9331751,0.07442837781,-1.86147852
2.62,15.93187835,0.07445808035,-1.862162929
2.63,15.93057823,0.07448790828,-1.862849917
2.64,15.92927478,0.07451786176,-1.863539483
2.65,15.92796804,0.07454794094,-1.864231627
2.66,15.92665805,0.07457814596,-1.864926346
2.67,15.92534487,0.07460847698,-1.865623641
2.68,15.92402853,0.07463893415,-1.866323509
2.69,15.92270908,0.07466951763,-1.867025949
2.7,15.92138657,0.07470022758,-1.86773096
2.71,15.92006103,0.07473106414,-1.868438541
2.72,15.91873252,0.07476202748,-1.869148689
2.73,15.91740108,0.07479311776,-1.869861405
2.74,15.91606676,0.07482433514,-1.870576686
2.75,15.9147296,0.07485567977,-1.87129453
2.76,15.91338965,0.07488715183,-1.872014938
2.77,15.91204695,0.07491875148,-1.872737906
2.78,15.91070156,0.07495047888,-1.873463434
2.79,15.90935352,0.0749823342,-1.874191519
2.8,15.90800288,0.0750143176,-1.87492216
2.81,15.90664969,0.07504642926,-1.875655356
2.82,15.90529399,0.07507866934,-1.876391105
2.83,15.90393583,0.07511103802,-1.877129405
2.84,15.90257527,0.07514353546,-1.877870254
2.85,15.90121234,0.07517616183,-1.878613651
2.86,15.89984709,0.07520891732,-1.879359593
2.87,15.89847958,0.07524180209,-1.880108079
2.88,15.89710984,0.07527481633,-1.880859107
2.89,15.89573794,0.07530796022,-1.881612676
2.9,15.89436391,0.07534123393,-1.882368783
2.91,15.89298781,0.07537463765,-1.883127427
2.92,15.89160968,0.07540817157,-1.883888606
2.93,15.89022958,0.07544183586,-1.884652319
2.94,15.88884755,0.07547563073,-1.885418563
2.95,15.88746364,0.07550955635,-1.886187336
2.96,15.8860779,0.07554361293,-1.886958636
2.97,15.88469038,0.07557780065,-1.887732462
2.98,15.88330111,0.07561211972,-1.888508811
2.99,15.88191015,0.07564657032,-1.889287682
3,15.88051754,0.07568115268,-1.89006907
3.01,15.87912332,0.07571586699,-1.890852975
3.02,15.87772753,0.07575071347,-1.891639392
3.03,15.87633021,0.07578569234,-1.89242832
3.04,15.87493141,0.07582080382,-1.893219755
3.05,15.87353117,0.07585604813,-1.894013694
3.06,15.87212953,0.07589142549,-1.894810134
3.07,15.87072653,0.07592693614,-1.895609072
3.08,15.86932221,0.07596258029,-1.896410504
3.09,15.86791661,0.07599835818,-1.897214427
3.1,15.86650977,0.07603427005,-1.898020838
3.11,15.86510173,0.07607031612,-1.898829733
3.12,15.86369252,0.07610649663,-1.899641107
3.13,15.86228219,0.07614281183,-1.900454958
3.14,15.86087078,0.07617926196,-1.901271281
3.15,15.85945833,0.07621584726,-1.902090073
3.16,15.85804488,0.07625256797,-1.90291133
3.17,15.85663048,0.07628942435,-1.903735048
3.18,15.85521515,0.07632641664,-1.904561223
3.19,15.85379896,0.0763635451,-1.90538985
3.2,15.85238193,0.07640080998,-1.906220927
3.21,15.8509641,0.07643821153,-1.907054448
3.22,15.84954553,0.07647575002,-1.90789041
3.23,15.84812625,0.0765134257,-1.908728808
3.24,15.8467063,0.07655123885,-1.909569638
3.25,15.84528572,0.07658918973,-1.910412895
3.26,15.84386456,0.07662727861,-1.911258576
3.27,15.84244286,0.07666550577,-1.912106676
3.28,15.84102065,0.07670387147,-1.91295719
3.29,15.83959799,0.07674237599,-1.913810114
3.3,15.83817491,0.07678101961,-1.914665444
3.31,15.83675145,0.07681980259,-1.915523173
3.32,15.83532766,0.07685872523,-1.916383299
3.33,15.83390358,0.07689778781,-1.917245815
3.34,15.83247924,0.0769369906,-1.918110717
3.35,15.83105471,0.0769763339,-1.918978001
3.36,15.82963001,0.07701581798,-1.919847661
3.37,15.8282052,0.07705544316,-1.920719693
3.38,15.82678032,0.07709520971,-1.921594092
3.39,15.82535542,0.07713511793,-1.922470853
3.4,15.82393053,0.07717516812,-1.92334997
3.41,15.82250571,0.0772153606,-1.924231439
3.42,15.821081,0.07725569565,-1.925115254
3.43,15.81965643,0.07729617359,-1.926001409
3.44,15.81823206,0.07733679473,-1.926889899
3.45,15.81680792,0.07737755939,-1.927780717
3.46,15.81538407,0.07741846788,-1.928673858
3.47,15.81396054,0.07745952053,-1.929569315
3.48,15.81253739,0.07750071765,-1.930467082
3.49,15.81111467,0.07754205958,-1.931367152
3.5,15.80969242,0.07758354662,-1.932269519
3.51,15.80827071,0.0776251791,-1.933174177
3.52,15.80684961,0.0776669573,-1.934081118
3.53,15.80542916,0.07770888153,-1.934990336
3.54,15.80400945,0.07775095205,-1.935901822
3.55,15.80259055,0.07779316915,-1.936815566
3.56,15.80117253,0.07783553307,-1.937731561
3.57,15.79975547,0.07787804409,-1.938649798
3.58,15.79833944,0.07792070245,-1.939570267
3.59,15.79692452,0.07796350838,-1.940492959
3.6,15.79551079,0.07800646214,-1.941417864
3.61,15.79409832,0.07804956396,-1.942344973
3.62,15.79268719,0.0780928141,-1.943274274
3.63,15.79127745,0.07813621279,-1.944205758
3.64,15.7898692,0.07817976029,-1.945139413
3.65,15.78846248,0.07822345683,-1.94607523
3.66,15.78705738,0.07826730266,-1.947013197
3.67,15.78565397,0.07831129802,-1.947953303
3.68,15.78425233,0.07835544316,-1.948895537
3.69,15.78285253,0.0783997383,-1.949839887
3.7,15.78145466,0.0784441837,-1.950786341
3.71,15.7800588,0.07848877959,-1.951734889
3.72,15.77866503,0.0785335262,-1.952685518
3.73,15.77727342,0.07857842377,-1.953638215
3.74,15.77588407,0.07862347254,-1.954592969
3.75,15.77449704,0.07866867274,-1.955549767
3.76,15.77311243,0.07871402462,-1.956508597
3.77,15.77173031,0.0787595284,-1.957469444
3.78,15.77035076,0.07880518434,-1.958432297
3.79,15.76897387,0.07885099265,-1.959397143
3.8,15.76759971,0.07889695357,-1.960363967
3.81,15.76622837,0.07894306734,-1.961332758
3.82,15.76485993,0.0789893342,-1.962303501
3.83,15.76349446,0.07903575436,-1.963276183
3.84,15.76213206,0.07908232807,-1.964250789
3.85,15.76077279,0.07912905557,-1.965227306
3.86,15.75941673,0.07917593708,-1.966205719
3.87,15.75806398,0.07922297284,-1.967186013
3.88,15.7567146,0.0792701631,-1.968168175
3.89,15.75536868,0.0793175081,-1.969152189
3.9,15.75402629,0.07936500807,-1.970138042
3.91,15.75268753,0.07941266326,-1.971125718
3.92,15.75135246,0.07946047392,-1.972115204
3.93,15.75002117,0.07950844027,-1.973106484
3.94,15.74869373,0.07955656258,-1.974099543
3.95,15.74737022,0.07960484107,-1.975094366
3.96,15.74605072,0.07965327601,-1.976090936
3.97,15.7447353,0.07970186764,-1.977089239
3.98,15.74342405,0.07975061622,-1.978089256
3.99,15.74211705,0.07979952199,-1.979090973
4,15.74081437,0.07984858521,-1.980094373
4.01,15.73951611,0.07989780613,-1.981099438
4.02,15.73822233,0.07994718501,-1.982106152
4.03,15.73693312,0.0799967221,-1.983114498
4.04,15.73564857,0.08004641767,-1.984124457
4.05,15.73436876,0.08009627197,-1.985136013
4.06,15.73309376,0.08014628526,-1.986149147
4.07,15.73182368,0.08019645777,-1.98716384
4.08,15.73055858,0.08024678977,-1.988180075
4.09,15.72929855,0.08029728149,-1.989197832
4.1,15.72804368,0.08034793317,-1.990217093
4.11,15.72679404,0.08039874506,-1.991237839
4.12,15.72554973,0.08044971741,-1.992260051
4.13,15.72431081,0.08050085047,-1.99328371
4.14,15.72307737,0.08055214448,-1.994308797
4.15,15.72184948,0.0806035997,-1.995335291
4.16,15.72062724,0.08065521639,-1.996363174
4.17,15.71941072,0.08070699479,-1.997392427
4.18,15.71819999,0.08075893516,-1.99842303
4.19,15.71699515,0.08081103776,-1.999454963
4.2,15.71579626,0.08086330285,-2.000488207
4.21,15.71460341,0.08091573067,-2.001522741
4.22,15.71341666,0.08096832149,-2.002558544
4.23,15.71223611,0.08102107558,-2.003595598
4.24,15.71106182,0.08107399318,-2.004633882
4.25,15.70989389,0.08112707457,-2.005673377
4.26,15.70873239,0.08118032002,-2.006714061
4.27,15.70757741,0.08123372978,-2.007755916
4.28,15.70642903,0.08128730412,-2.008798922
4.29,15.70528734,0.0813410433,-2.009843057
4.3,15.70415243,0.0813949476,-2.010888302
4.31,15.7030244,0.08144901727,-2.011934637
4.32,15.70190333,0.08150325259,-2.01298204
4.33,15.70078931,0.08155765381,-2.01403049
4.34,15.69968244,0.08161222121,-2.015079968
4.35,15.6985828,0.08166695505,-2.016130452
4.36,15.69749049,0.08172185562,-2.01718192
4.37,15.6964056,0.08177692317,-2.018234352
4.38,15.69532821,0.08183215799,-2.019287725
4.39,15.69425843,0.08188756033,-2.020342017
4.4,15.69319633,0.08194313048,-2.021397207
4.41,15.69214202,0.0819988687,-2.02245327
4.42,15.69109559,0.08205477528,-2.023510184
4.43,15.69005713,0.08211085049,-2.024567927
4.44,15.68902673,0.08216709461,-2.025626474
4.45,15.6880045,0.08222350791,-2.026685804
4.46,15.68699053,0.08228009068,-2.027745892
4.47,15.68598491,0.0823368432,-2.028806714
4.48,15.68498774,0.08239376577,-2.029868247
4.49,15.68399912,0.08245085866,-2.030930466
4.5,15.68301914,0.08250812217,-2.031993346
4.51,15.68204791,0.08256555658,-2.033056864
4.52,15.68108551,0.0826231622,-2.034120993
4.53,15.68013204,0.08268093932,-2.035185709
4.54,15.67918761,0.08273888823,-2.036250986
4.55,15.6782523,0.08279700925,-2.0373168
4.56,15.67732623,0.08285530266,-2.038383125
4.57,15.67640948,0.08291376878,-2.039449935
4.58,15.67550216,0.0829724079,-2.040517204
4.59,15.67460438,0.08303122033,-2.041584906
4.6,15.67371623,0.08309020638,-2.042653015
4.61,15.67283781,0.08314936636,-2.043721505
4.62,15.67196922,0.08320870058,-2.044790349
4.63,15.67111056,0.08326820936,-2.045859521
4.64,15.67026193,0.08332789302,-2.046928993
4.65,15.66942343,0.08338775187,-2.047998739
4.66,15.66859516,0.08344778624,-2.049068732
4.67,15.66777721,0.08350799646,-2.050138945
4.68,15.66696968,0.08356838285,-2.051209351
4.69,15.66617268,0.08362894573,-2.052279922
4.7,15.66538631,0.08368968544,-2.053350629
4.71,15.66461066,0.08375060229,-2.054421445
4.72,15.66384585,0.08381169663,-2.055492343
4.73,15.66309197,0.08387296878,-2.056563292
4.74,15.66234913,0.08393441908,-2.057634266
4.75,15.66161744,0.08399604785,-2.058705236
4.76,15.66089698,0.08405785544,-2.059776173
4.77,15.66018786,0.08411984216,-2.060847047
4.78,15.65949019,0.08418200837,-2.061917831
4.79,15.65880407,0.08424435441,-2.062988495
4.8,15.65812959,0.0843068806,-2.064059009
4.81,15.65746687,0.08436958731,-2.065129346
4.82,15.65681599,0.08443247487,-2.066199474
4.83,15.65617708,0.08449554364,-2.067269365
4.84,15.65555022,0.08455879397,-2.068338989
4.85,15.65493552,0.08462222621,-2.069408315
4.86,15.65433308,0.08468584071,-2.070477313
4.87,15.65374301,0.08474963783,-2.071545953
4.88,15.65316541,0.08481361791,-2.072614204
4.89,15.65260038,0.08487778132,-2.073682034
4.9,15.65204803,0.08494212843,-2.074749413
4.91,15.65150846,0.08500665958,-2.07581631
4.92,15.65098176,0.08507137515,-2.076882694
4.93,15.65046805,0.0851362755,-2.077948533
4.94,15.64996739,0.085201361,-2.079013795
4.95,15.64947989,0.08526663202,-2.080078445
4.96,15.64900561,0.08533208893,-2.081142451
4.97,15.64854466,0.08539773209,-2.082205778
4.98,15.64809712,0.08546356187,-2.083268395
4.99,15.64766306,0.08552957862,-2.084330265
5,15.64724259,0.08559578268,-2.085391355
5.01,15.64683579,0.08566217439,-2.086451629
5.02,15.64644276,0.08572875407,-2.087511052
5.03,15.64606361,0.085795522,-2.088569587
5.04,15.64569842,0.08586247843,-2.089627196
5.05,15.64534727,0.0859296236,-2.090683844
5.06,15.64501025,0.08599695769,-2.091739492
5.07,15.64468741,0.08606448094,-2.092794105
5.08,15.64437882,0.08613219355,-2.093847648
5.09,15.64408454,0.08620009574,-2.094900087
5.1,15.64380464,0.08626818773,-2.095951388
5.11,15.64353919,0.08633646969,-2.097001517
5.12,15.64328824,0.0864049418,-2.098050442
5.13,15.64305189,0.08647360423,-2.099098129
5.14,15.64283021,0.08654245712,-2.100144545
5.15,15.64262327,0.08661150061,-2.101189656
5.16,15.64243117,0.08668073484,-2.102233427
5.17,15.64225397,0.08675015991,-2.103275825
5.18,15.64209174,0.08681977595,-2.104316815
5.19,15.64194455,0.0868895831,-2.105356361
5.2,15.64181246,0.08695958146,-2.106394427
5.21,15.64169552,0.08702977115,-2.107430977
5.22,15.64159379,0.0871001523,-2.108465974
5.23,15.64150734,0.08717072504,-2.109499383
5.24,15.64143621,0.0872414895,-2.110531169
5.25,15.64138045,0.08731244583,-2.111561296
5.26,15.6413401,0.08738359417,-2.112589727
5.27,15.64131521,0.08745493466,-2.113616427
5.28,15.6413058,0.08752646743,-2.114641357
5.29,15.6413119,0.08759819262,-2.115664482
5.3,15.64133354,0.08767011038,-2.116685762
5.31,15.64137074,0.08774222084,-2.117705162
5.32,15.64142353,0.08781452413,-2.118722642
5.33,15.64149193,0.08788702041,-2.119738166
5.34,15.64157596,0.08795970982,-2.120751695
5.35,15.64167563,0.0880325925,-2.12176319
5.36,15.64179097,0.08810566859,-2.122772613
5.37,15.64192197,0.08817893822,-2.123779925
5.38,15.64206867,0.08825240154,-2.124785089
5.39,15.64223108,0.08832605868,-2.125788066
5.4,15.64240921,0.0883999098,-2.126788818
5.41,15.64260307,0.08847395502,-2.127787308
5.42,15.6428127,0.0885481945,-2.128783496
5.43,15.6430381,0.08862262836,-2.129777347
5.44,15.64327928,0.08869725675,-2.130768821
5.45,15.64353625,0.08877207981,-2.131757881
5.46,15.64380902,0.08884709767,-2.132744489
5.47,15.6440976,0.08892231048,-2.133728606
5.48,15.64440198,0.08899771837,-2.134710192
5.49,15.64472217,0.08907332147,-2.13568921
5.5,15.64505816,0.08914911991,-2.136665619
5.51,15.64540995,0.08922511381,-2.13763938
5.52,15.64577753,0.08930130329,-2.138610453
5.53,15.64616089,0.08937768845,-2.139578798
5.54,15.64656002,0.08945426942,-2.140544374
5.55,15.6469749,0.08953104629,-2.141507142
5.56,15.64740553,0.08960801918,-2.142467059
5.57,15.64785188,0.08968518817,-2.143424085
5.58,15.64831395,0.08976255337,-2.144378178
5.59,15.64879172,0.08984011487,-2.145329296
5.6,15.64928517,0.08991787277,-2.146277399
5.61,15.6497943,0.08999582717,-2.147222444
5.62,15.65031908,0.09007397818,-2.148164392
5.63,15.65085951,0.09015232589,-2.149103199
5.64,15.65141557,0.09023087041,-2.150038825
5.65,15.65198724,0.09030961183,-2.150971228
5.66,15.65257453,0.09038855025,-2.151900366
5.67,15.65317741,0.09046768576,-2.152826199
5.68,15.65379588,0.09054701845,-2.153748684
5.69,15.65442994,0.09062654841,-2.154667781
5.7,15.65507956,0.09070627573,-2.155583449
5.71,15.65574473,0.09078620053,-2.156495647
5.72,15.65642545,0.09086632289,-2.157404335
5.73,15.65712171,0.0909466429,-2.158309471
5.74,15.65783348,0.09102716065,-2.159211014
5.75,15.65856077,0.09110787621,-2.160108922
5.76,15.65930355,0.09118878967,-2.161003154
5.77,15.66006181,0.09126990112,-2.161893667
5.78,15.66083554,0.09135121062,-2.162780421
5.79,15.66162471,0.09143271827,-2.163663374
5.8,15.66242931,0.09151442412,-2.164542483
5.81,15.66324931,0.09159632825,-2.165417706
5.82,15.6640847,0.09167843075,-2.166289002
5.83,15.66493545,0.09176073168,-2.167156329
5.84,15.66580154,0.09184323111,-2.168019646
5.85,15.66668295,0.09192592911,-2.168878909
5.86,15.66757964,0.09200882576,-2.169734075
5.87,15.66849158,0.09209192112,-2.170585103
5.88,15.66941874,0.09217521528,-2.17143195
5.89,15.67036109,0.0922587083,-2.172274571
5.9,15.67131859,0.09234240027,-2.173112924
5.91,15.67229119,0.09242629125,-2.173946965
5.92,15.67327884,0.09251038129,-2.174776651
5.93,15.6742815,0.09259467047,-2.175601936
5.94,15.67529911,0.09267915883,-2.176422778
5.95,15.67633163,0.0927638464,-2.177239132
5.96,15.67737902,0.09284873322,-2.178050953
5.97,15.67844124,0.09293381931,-2.178858197
5.98,15.67951826,0.09301910467,-2.179660819
5.99,15.68061004,0.09310458932,-2.180458774
6,15.68171655,0.09319027328,-2.181252016
6.01,15.68283774,0.09327615657,-2.182040499
6.02,15.68397355,0.09336223918,-2.182824179
6.03,15.68512394,0.09344852111,-2.183603012
6.04,15.68628886,0.09353500231,-2.184376953
6.05,15.68746824,0.09362168273,-2.185145958
6.06,15.68866201,0.09370856227,-2.185909985
6.07,15.6898701,0.09379564081,-2.186668988
6.08,15.69109243,0.09388291821,-2.187422929
6.09,15.69232894,0.0939703943,-2.188171766
6.1,15.69357957,0.09405806893,-2.188915457
6.11,15.69484427,0.09414594192,-2.189653963
6.12,15.696123,0.09423401311,-2.190387244
6.13,15.6974157,0.09432228228,-2.191115257
6.14,15.69872235,0.09441074923,-2.191837963
6.15,15.70004288,0.09449941371,-2.192555319
6.16,15.70137727,0.09458827548,-2.193267284
6.17,15.70272545,0.0946773343,-2.193973815
6.18,15.70408738,0.09476658989,-2.194674872
6.19,15.705463,0.094856042,-2.195370411
6.2,15.70685227,0.09494569031,-2.196060393
6.21,15.70825513,0.09503553453,-2.196744777
6.22,15.70967156,0.0951255743,-2.197423523
6.23,15.71110149,0.09521580927,-2.198096593
6.24,15.71254488,0.0953062391,-2.198763948
6.25,15.71400167,0.0953968634,-2.199425547
6.26,15.7154718,0.09548768182,-2.200081351
6.27,15.71695519,0.09557869398,-2.200731319
6.28,15.71845177,0.09566989948,-2.201375412
6.29,15.71996149,0.09576129791,-2.202013589
6.3,15.72148427,0.09585288886,-2.202645811
6.31,15.72302004,0.09594467186,-2.203272039
6.32,15.72456872,0.09603664646,-2.203892235
6.33,15.72613023,0.09612881218,-2.204506359
6.34,15.7277045,0.09622116856,-2.205114372
6.35,15.72929146,0.09631371509,-2.205716235
6.36,15.73089102,0.09640645127,-2.206311907
6.37,15.73250313,0.09649937657,-2.206901348
6.38,15.73412771,0.09659249046,-2.207484521
6.39,15.7357647,0.09668579244,-2.208061388
6.4,15.73741405,0.09677928198,-2.208631914
6.41,15.73907567,0.09687295859,-2.209196065
6.42,15.7407495,0.09696682175,-2.209753805
6.43,15.74243547,0.09706087096,-2.210305098
6.44,15.74413352,0.09715510571,-2.210849912
6.45,15.74584357,0.09724952548,-2.211388212
6.46,15.74756555,0.09734412975,-2.211919965
6.47,15.74929939,0.09743891802,-2.212445143
6.48,15.75104504,0.09753388976,-2.212963712
6.49,15.75280241,0.09762904446,-2.213475643
6.5,15.75457143,0.0977243816,-2.213980904
6.51,15.75635206,0.09781990062,-2.214479462
6.52,15.75814425,0.09791560097,-2.214971288
6.53,15.75994794,0.09801148205,-2.215456351
6.54,15.7617631,0.09810754328,-2.215934621
6.55,15.76358968,0.09820378401,-2.21640607
6.56,15.76542763,0.09830020362,-2.216870666
6.57,15.76727689,0.09839680144,-2.217328382
6.58,15.76913742,0.09849357681,-2.217779188
6.59,15.77100916,0.09859052905,-2.218223057
6.6,15.77289204,0.0986876575,-2.218659959
6.61,15.77478602,0.09878496149,-2.219089867
6.62,15.77669104,0.09888244032,-2.219512754
6.63,15.77860705,0.09898009328,-2.219928593
6.64,15.78053401,0.09907791967,-2.220337358
6.65,15.78247187,0.09917591875,-2.220739022
6.66,15.78442059,0.09927408979,-2.221133558
6.67,15.78638014,0.09937243208,-2.221520938
6.68,15.7883505,0.09947094491,-2.221901137
6.69,15.79033166,0.09956962757,-2.222274129
6.7,15.79232362,0.09966847933,-2.222639888
6.71,15.79432637,0.09976749944,-2.222998389
6.72,15.79633988,0.09986668714,-2.223349606
6.73,15.79836413,0.09996604165,-2.223693512
6.74,15.80039908,0.1000655622,-2.224030083
6.75,15.8024447,0.1001652479,-2.224359292
6.76,15.80450094,0.100265098,-2.224681114
6.77,15.8065678,0.1003651116,-2.224995522
6.78,15.80864525,0.1004652878,-2.225302494
6.79,15.81073326,0.1005656259,-2.225602005
6.8,15.81283183,0.1006661249,-2.225894029
6.81,15.81494093,0.100766784,-2.226178543
6.82,15.81706055,0.1008676023,-2.226455523
6.83,15.81919065,0.1009685791,-2.226724942
6.84,15.82133122,0.1010697135,-2.226986775
6.85,15.82348222,0.1011710048,-2.227241001
6.86,15.82564361,0.1012724521,-2.227487596
6.87,15.82781535,0.1013740544,-2.227726538
6.88,15.82999736,0.1014758111,-2.227957805
6.89,15.8321896,0.1015777213,-2.228181373
6.9,15.83439198,0.1016797842,-2.228397219
6.91,15.83660445,0.101781999,-2.228605319
6.92,15.83882694,0.101884365,-2.228805654
6.93,15.84105944,0.1019868813,-2.228998203
6.94,15.84330191,0.1020895472,-2.229182949
6.95,15.84555434,0.1021923619,-2.229359873
6.96,15.84781666,0.1022953244,-2.229528959
6.97,15.85008885,0.102398434,-2.229690188
6.98,15.85237085,0.1025016899,-2.229843543
6.99,15.85466259,0.1026050913,-2.229989006
7,15.856964,0.1027086374,-2.230126557
7.01,15.85927502,0.1028123275,-2.23025618
7.02,15.86159559,0.1029161607,-2.230377858
7.03,15.86392566,0.1030201363,-2.230491573
7.04,15.86626516,0.1031242533,-2.230597308
7.05,15.86861406,0.1032285111,-2.23069505
7.06,15.8709723,0.1033329086,-2.230784784
7.07,15.87333984,0.103437445,-2.230866501
7.08,15.87571663,0.1035421195,-2.230940189
7.09,15.87810262,0.1036469312,-2.231005837
7.1,15.88049776,0.1037518792,-2.231063438
7.11,15.88290201,0.1038569624,-2.231112979
7.12,15.88531532,0.10396218,-2.231154455
7.13,15.88773765,0.1040675309,-2.231187858
7.14,15.89016891,0.1041730143,-2.23121318
7.15,15.89260909,0.1042786292,-2.231230413
7.16,15.89505814,0.1043843746,-2.231239548
7.17,15.89751604,0.1044902495,-2.231240574
7.18,15.89998278,0.1045962531,-2.231233483
7.19,15.90245837,0.1047023842,-2.231218266
7.2,15.90494282,0.1048086419,-2.231194913
7.21,15.90743615,0.104915025,-2.231163417
7.22,15.90993839,0.1050215324,-2.231123772
7.23,15.91244957,0.1051281631,-2.231075971
7.24,15.91496971,0.1052349158,-2.231020006
7.25,15.91749888,0.1053417895,-2.23095587
7.26,15.92003713,0.1054487828,-2.230883557
7.27,15.92258452,0.1055558945,-2.23080306
7.28,15.92514113,0.1056631233,-2.230714373
7.29,15.92770704,0.1057704679,-2.230617487
7.3,15.93028231,0.1058779268,-2.230512395
7.31,15.93286704,0.1059854988,-2.230399088
7.32,15.93546133,0.1060931822,-2.230277561
7.33,15.93806528,0.1062009758,-2.230147806
7.34,15.940679,0.1063088781,-2.230009815
7.35,15.94330256,0.1064168878,-2.229863575
7.36,15.94593607,0.1065250034,-2.229709073
7.37,15.9485796,0.1066332235,-2.229546297
7.38,15.95123325,0.1067415466,-2.229375232
7.39,15.95389711,0.1068499714,-2.229195862
7.4,15.95657127,0.1069584963,-2.229008176
7.41,15.95925582,0.1070671201,-2.22881216
7.42,15.96195084,0.1071758412,-2.2286078
7.43,15.96465641,0.1072846584,-2.228395082
7.44,15.96737261,0.1073935703,-2.228173991
7.45,15.97009953,0.1075025755,-2.227944514
7.46,15.97283724,0.1076116724,-2.227706637
7.47,15.97558583,0.1077208598,-2.227460347
7.48,15.97834536,0.1078301361,-2.22720563
7.49,15.98111592,0.1079395,-2.22694247
7.5,15.98389755,0.1080489501,-2.226670849
7.51,15.98669031,0.1081584849,-2.226390748
7.52,15.98949421,0.1082681032,-2.226102147
7.53,15.99230927,0.1083778036,-2.225805026
7.54,15.9951355,0.1084875847,-2.225499368
7.55,15.99797291,0.1085974452,-2.225185155
7.56,16.00082152,0.1087073838,-2.224862369
7.57,16.00368138,0.1088173992,-2.224530991
7.58,16.0065525,0.1089274899,-2.224191002
7.59,16.00943495,0.1090376549,-2.223842384
7.6,16.01232876,0.1091478927,-2.223485118
7.61,16.015234,0.1092582021,-2.22311919
7.62,16.01815074,0.1093685819,-2.222744585
7.63,16.02107905,0.1094790307,-2.222361288
7.64,16.02401902,0.1095895472,-2.221969287
7.65,16.02697073,0.1097001302,-2.221568573
7.66,16.02993427,0.1098107783,-2.221159136
7.67,16.0329097,0.1099214903,-2.220740967
7.68,16.0358971,0.1100322648,-2.220314056
7.69,16.03889655,0.1101431005,-2.219878393
7.7,16.0419081,0.1102539962,-2.219433972
7.71,16.04493185,0.1103649505,-2.218980785
7.72,16.04796786,0.1104759621,-2.218518825
7.73,16.05101621,0.1105870298,-2.218048085
7.74,16.05407698,0.1106981522,-2.217568558
7.75,16.05715026,0.110809328,-2.217080239
7.76,16.06023612,0.110920556,-2.21658312
7.77,16.06333467,0.1110318348,-2.216077199
7.78,16.06644594,0.1111431633,-2.215562471
7.79,16.06956999,0.1112545401,-2.215038929
7.8,16.07270687,0.1113659642,-2.214506569
7.81,16.07585664,0.1114774343,-2.213965387
7.82,16.07901934,0.1115889494,-2.21341538
7.83,16.08219505,0.1117005082,-2.212856545
7.84,16.08538382,0.1118121097,-2.212288883
7.85,16.08858571,0.1119237528,-2.211712392
7.86,16.09180079,0.1120354364,-2.211127072
7.87,16.0950291,0.1121471594,-2.210532924
7.88,16.09827068,0.1122589209,-2.20992995
7.89,16.10152559,0.1123707197,-2.209318155
7.9,16.10479387,0.112482555,-2.208697544
7.91,16.10807556,0.1125944256,-2.208068126
7.92,16.11137069,0.1127063307,-2.207429911
7.93,16.11467929,0.1128182694,-2.206782909
7.94,16.11800137,0.1129302408,-2.206127136
7.95,16.12133696,0.1130422439,-2.205462607
7.96,16.12468607,0.113154278,-2.204789342
7.97,16.12804874,0.1132663421,-2.204107361
7.98,16.131425,0.1133784356,-2.203416691
7.99,16.13481488,0.1134905577,-2.202717356
8,16.13821841,0.1136027077,-2.202009386
8.01,16.14163561,0.1137148849,-2.201292807
8.02,16.14506649,0.1138270887,-2.200567649
8.03,16.14851107,0.1139393183,-2.19983394
8.04,16.1519694,0.1140515732,-2.19909171
8.05,16.15544148,0.1141638525,-2.198340989
8.06,16.15892737,0.1142761556,-2.197581808
8.07,16.16242709,0.1143884817,-2.1968142
8.08,16.16594069,0.11450083,-2.196038193
8.09,16.16946821,0.1146131999,-2.19525382
8.1,16.17300971,0.1147255906,-2.194461108
8.11,16.17656521,0.1148380012,-2.193660085
8.12,16.18013476,0.1149504311,-2.192850782
8.13,16.18371841,0.1150628794,-2.192033225
8.14,16.18731621,0.1151753455,-2.191207444
8.15,16.19092822,0.1152878284,-2.190373469
8.16,16.19455449,0.1154003273,-2.189531328
8.17,16.19819507,0.1155128413,-2.188681049
8.18,16.20185003,0.1156253694,-2.187822662
8.19,16.2055194,0.1157379108,-2.186956194
8.2,16.20920325,0.1158504646,-2.186081673
8.21,16.21290163,0.1159630298,-2.185199125
8.22,16.21661458,0.1160756056,-2.184308578
8.23,16.22034214,0.1161881911,-2.183410061
8.24,16.2240843,0.1163007855,-2.182503601
8.25,16.22784109,0.1164133879,-2.181589227
8.26,16.23161249,0.1165259973,-2.180666965
8.27,16.23539853,0.1166386129,-2.179736842
8.28,16.23919923,0.1167512337,-2.178798886
8.29,16.2430146,0.1168638587,-2.17785312
8.3,16.24684464,0.1169764869,-2.17689957
8.31,16.25068938,0.1170891172,-2.175938263
8.32,16.25454882,0.1172017488,-2.174969225
8.33,16.25842295,0.1173143805,-2.173992481
8.34,16.26231175,0.1174270114,-2.173008062
8.35,16.26621518,0.1175396407,-2.172015995
8.36,16.27013323,0.1176522675,-2.17101631
8.37,16.27406587,0.1177648909,-2.170009036
8.38,16.27801305,0.11787751,-2.168994202
8.39,16.28197472,0.1179901241,-2.167971837
8.4,16.28595079,0.1181027324,-2.166941973
8.41,16.28994116,0.118215334,-2.165904639
8.42,16.29394573,0.1183279281,-2.164859868
8.43,16.2979644,0.1184405138,-2.163807696
8.44,16.30199705,0.1185530902,-2.162748155
8.45,16.30604357,0.1186656565,-2.161681282
8.46,16.3101039,0.1187782115,-2.160607112
8.47,16.31417794,0.1188907544,-2.159525681
8.48,16.31826561,0.1190032843,-2.158437027
8.49,16.32236682,0.1191158001,-2.157341185
8.5,16.32648147,0.1192283009,-2.156238187
8.51,16.33060945,0.1193407855,-2.15512806
8.52,16.33475064,0.1194532531,-2.154010836
8.53,16.33890494,0.1195657025,-2.152886544
8.54,16.3430722,0.119678133,-2.151755215
8.55,16.34725228,0.1197905435,-2.150616878
8.56,16.35144502,0.1199029331,-2.149471565
8.57,16.35565028,0.1200153009,-2.148319309
8.58,16.35986789,0.1201276461,-2.147160145
8.59,16.36409775,0.1202399677,-2.145994108
8.6,16.36833975,0.120352265,-2.144821231
8.61,16.3725938,0.1204645369,-2.143641549
8.62,16.37685982,0.1205767824,-2.142455097
8.63,16.38113774,0.1206890006,-2.141261906
8.64,16.38542748,0.1208011906,-2.140062005
8.65,16.38972896,0.1209133512,-2.138855419
8.66,16.39404208,0.1210254816,-2.137642175
8.67,16.39836676,0.1211375805,-2.136422299
8.68,16.40270293,0.1212496471,-2.135195818
8.69,16.4070505,0.1213616801,-2.133962758
8.7,16.41140942,0.1214736786,-2.132723148
8.71,16.41577964,0.1215856414,-2.131477012
8.72,16.42016111,0.1216975674,-2.130224376
8.73,16.42455377,0.1218094555,-2.128965263
8.74,16.42895755,0.1219213047,-2.1276997
8.75,16.4333724,0.1220331139,-2.126427713
8.76,16.43779827,0.1221448818,-2.125149328
8.77,16.44223509,0.1222566073,-2.123864567
8.78,16.44668281,0.1223682894,-2.122573452
8.79,16.45114132,0.1224799269,-2.121276003
8.8,16.45561054,0.1225915189,-2.119972244
8.81,16.46009039,0.1227030643,-2.118662201
8.82,16.46458082,0.122814562,-2.117345896
8.83,16.46908178,0.1229260109,-2.116023352
8.84,16.47359321,0.12303741,-2.114694587
8.85,16.47811501,0.123148758,-2.113359622
8.86,16.48264711,0.1232600538,-2.11201847
8.87,16.48718938,0.1233712963,-2.110671143
8.88,16.49174171,0.1234824847,-2.109317656
8.89,16.49630399,0.1235936179,-2.107958026
8.9,16.50087612,0.123704695,-2.106592272
8.91,16.50545799,0.1238157151,-2.105220414
8.92,16.51004951,0.1239266774,-2.103842473
8.93,16.51465057,0.124037581,-2.102458472
8.94,16.51926106,0.1241484251,-2.101068424
8.95,16.52388086,0.124259209,-2.099672346
8.96,16.52850987,0.1243699322,-2.098270264
8.97,16.53314796,0.1244805943,-2.09686221
8.98,16.53779503,0.124591195,-2.095448217
8.99,16.54245096,0.1247017342,-2.094028324
9,16.54711564,0.1248122114,-2.09260257
9.01,16.55178892,0.1249226266,-2.091170993
9.02,16.55647067,0.1250329791,-2.089733631
9.03,16.5611607,0.1251432688,-2.088290525
9.04,16.56585888,0.125253495,-2.086841715
9.05,16.57056507,0.1253636574,-2.085387242
9.06,16.57527911,0.1254737552,-2.083927148
9.07,16.58000087,0.1255837881,-2.082461478
9.08,16.58473024,0.1256937553,-2.080990277
9.09,16.58946705,0.1258036563,-2.079513588
9.1,16.5942112,0.1259134904,-2.078031459
9.11,16.59896256,0.126023257,-2.076543939
9.12,16.60372099,0.1261329553,-2.075051078
9.13,16.60848635,0.126242585,-2.073552925
9.14,16.61325853,0.1263521453,-2.072049526
9.15,16.6180374,0.1264616359,-2.070540928
9.16,16.62282283,0.1265710562,-2.069027179
9.17,16.62761471,0.1266804056,-2.067508324
9.18,16.63241294,0.1267896835,-2.065984405
9.19,16.63721741,0.1268988891,-2.064455466
9.2,16.64202803,0.1270080219,-2.06292155
9.21,16.6468447,0.1271170808,-2.061382702
9.22,16.65166734,0.1272260651,-2.059838965
9.23,16.65649584,0.1273349738,-2.058290381
9.24,16.6613301,0.1274438059,-2.056736991
9.25,16.66617001,0.1275525605,-2.05517884
9.26,16.67101545,0.1276612367,-2.053615978
9.27,16.67586635,0.1277698334,-2.05204845
9.28,16.68072258,0.1278783497,-2.050476305
9.29,16.685584,0.1279867845,-2.048899587
9.3,16.69045049,0.128095137,-2.047318337
9.31,16.69532191,0.1282034062,-2.045732594
9.32,16.70019814,0.1283115908,-2.044142392
9.33,16.70507905,0.12841969,-2.042547766
9.34,16.70996452,0.1285277024,-2.040948752
9.35,16.71485441,0.128635627,-2.039345386
9.36,16.71974857,0.1287434626,-2.037737704
9.37,16.72464683,0.1288512077,-2.036125739
9.38,16.72954899,0.1289588612,-2.034509527
9.39,16.73445486,0.1290664217,-2.032889103
9.4,16.73936424,0.1291738879,-2.031264508
9.41,16.74427697,0.1292812584,-2.029635785
9.42,16.7491929,0.1293885319,-2.028002976
9.43,16.75411188,0.1294957069,-2.026366124
9.44,16.75903377,0.1296027818,-2.024725269
9.45,16.76395845,0.129709755,-2.023080445
9.46,16.7688858,0.1298166248,-2.021431685
9.47,16.77381571,0.1299233895,-2.019779018
9.48,16.77874804,0.1300300472,-2.018122469
9.49,16.78368264,0.1301365963,-2.016462058
9.5,16.78861936,0.1302430349,-2.014797808
9.51,16.79355806,0.1303493611,-2.013129737
9.52,16.79849862,0.1304555729,-2.011457859
9.53,16.80344094,0.1305616685,-2.009782193
9.54,16.80838494,0.1306676456,-2.008102755
9.55,16.81333057,0.1307735023,-2.006419559
9.56,16.81827777,0.1308792363,-2.004732625
9.57,16.82322644,0.1309848455,-2.003041973
9.58,16.82817652,0.1310903278,-2.001347627
9.59,16.83312794,0.1311956809,-1.99964961
9.6,16.83808062,0.1313009026,-1.997947944
9.61,16.8430345,0.1314059907,-1.996242653
9.62,16.84798955,0.1315109428,-1.99453376
9.63,16.85294571,0.1316157565,-1.992821285
9.64,16.85790299,0.1317204292,-1.99110525
9.65,16.86286139,0.1318249582,-1.989385675
9.66,16.86782092,0.1319293408,-1.987662577
9.67,16.87278157,0.1320335744,-1.985935972
9.68,16.87774338,0.1321376564,-1.98420588
9.69,16.88270643,0.1322415841,-1.982472326
9.7,16.88767077,0.132345355,-1.98073533
9.71,16.89263645,0.1324489664,-1.978994907
9.72,16.89760352,0.1325524162,-1.977251078
9.73,16.90257199,0.1326557017,-1.975503866
9.74,16.90754188,0.1327588205,-1.973753299
9.75,16.9125132,0.1328617703,-1.971999404
9.76,16.91748596,0.1329645485,-1.970242214
9.77,16.92246015,0.1330671528,-1.968481759
9.78,16.92743575,0.1331695807,-1.966718069
9.79,16.93241273,0.1332718297,-1.964951176
9.8,16.93739108,0.1333738971,-1.963181116
9.81,16.94237079,0.1334757804,-1.961407921
9.82,16.94735183,0.1335774771,-1.959631627
9.83,16.95233422,0.1336789845,-1.957852272
9.84,16.95731793,0.1337803003,-1.956069899
9.85,16.96230292,0.1338814222,-1.954284553
9.86,16.96728915,0.1339823477,-1.952496281
9.87,16.97227657,0.1340830747,-1.950705127
9.88,16.97726514,0.1341836006,-1.948911137
9.89,16.98225479,0.134283923,-1.947114351
9.9,16.98724547,0.1343840396,-1.945314802
9.91,16.99223713,0.134483948,-1.943512519
9.92,16.99722974,0.134583646,-1.941707537
9.93,17.00222327,0.1346831315,-1.939899889
9.94,17.00721771,0.1347824024,-1.938089611
9.95,17.01221301,0.1348814567,-1.936276733
9.96,17.01720915,0.1349802925,-1.934461288
9.97,17.02220606,0.1350789079,-1.932643314
9.98,17.02720374,0.1351773009,-1.930822853
9.99,17.03220213,0.1352754697,-1.928999943
10,17.0372012,0.1353734124,-1.927174628
10.01,17.04220038,0.1354711146,-1.925347536
10.02,17.04719912,0.1355685626,-1.923519304
10.03,17.05220268,0.1356658793,-1.921684113
10.04,17.05720576,0.1357629368,-1.919847878
10.05,17.06220836,0.1358597345,-1.918010652
10.06,17.06721048,0.1359562715,-1.916172484
10.07,17.07221214,0.1360525472,-1.914333428
10.08,17.07721334,0.1361485609,-1.912493528
10.09,17.0822192,0.1362444237,-1.910646952
10.1,17.08722456,0.1363400191,-1.908799616
10.11,17.09222943,0.1364353465,-1.906951564
10.12,17.09723387,0.1365304051,-1.905102842
10.13,17.10224297,0.1366252984,-1.903247616
10.14,17.10725168,0.1367199175,-1.901391804
10.15,17.11226004,0.1368142618,-1.899535444
10.16,17.11726811,0.1369083303,-1.897678578
10.17,17.12227591,0.1370021222,-1.895821249
10.18,17.12728347,0.1370956369,-1.893963499
10.19,17.13229575,0.1371889657,-1.892099499
10.2,17.13730781,0.1372820116,-1.890235163
10.21,17.14231971,0.1373747735,-1.888370534
10.22,17.14733146,0.1374672506,-1.886505658
10.23,17.15234795,0.1375595263,-1.884634724
10.24,17.15736435,0.1376515111,-1.882763653
10.25,17.16237593,0.1377431237,-1.88089835
10.26,17.16739716,0.1378346039,-1.879021292
10.27,17.17241373,0.1379257098,-1.877150091
10.28,17.17743049,0.1380165206,-1.875278936
10.29,17.18245225,0.1381071074,-1.873402026
10.3,17.18747433,0.138197393,-1.871525259
10.31,17.19249685,0.1382873764,-1.869648687
10.32,17.1975199,0.1383770565,-1.867772364
10.33,17.20254822,0.1384664964,-1.865890519
10.34,17.20757723,0.1385556272,-1.86400904
10.35,17.21260237,0.1386443882,-1.862133798
10.36,17.21763757,0.1387329574,-1.860247408
10.37,17.22266906,0.1388211548,-1.858367372
10.38,17.22770156,0.1389090392,-1.856487933
10.39,17.23273979,0.1389966606,-1.854603356
10.4,17.23777928,0.1390839627,-1.852719502
10.41,17.24282016,0.1391709442,-1.850836428
10.42,17.24786257,0.1392576038,-1.848954192
10.43,17.25291117,0.1393439833,-1.847067093
10.44,17.25796157,0.1394300344,-1.845180967
10.45,17.26300941,0.1395157176,-1.843301624
10.46,17.26806834,0.1396011469,-1.841411889
10.47,17.273125,0.1396862061,-1.839529062
10.48,17.27818401,0.1397709324,-1.837647457
10.49,17.28324999,0.1398553546,-1.835761427
10.5,17.28831855,0.1399394375,-1.833876768
10.51,17.29338983,0.1400231799,-1.831993546
10.52,17.29846395,0.1401065808,-1.83011182
10.53,17.30354549,0.1401896598,-1.828225984
10.54,17.30863012,0.1402723905,-1.826341795
10.55,17.31371799,0.1403547717,-1.824459322
10.56,17.31880925,0.1404368024,-1.822578641
10.57,17.32390409,0.1405184813,-1.820699823
10.58,17.32900274,0.1405998073,-1.818822942
10.59,17.33410981,0.1406807866,-1.816942472
10.6,17.33922108,0.1407614061,-1.815064103
10.61,17.34433675,0.1408416647,-1.8131879
10.62,17.34945705,0.1409215612,-1.811313931
10.63,17.35458665,0.141001093,-1.809436722
10.64,17.35972131,0.1410802561,-1.807561911
10.65,17.36486124,0.1411590496,-1.805689566
10.66,17.37000665,0.1412374724,-1.803819744
10.67,17.37515774,0.1413155237,-1.801952504
10.68,17.38031473,0.1413932027,-1.800087914
10.69,17.38548234,0.1414704933,-1.798220591
10.7,17.39065626,0.1415474059,-1.796356077
10.71,17.39583671,0.1416239399,-1.794494429
10.72,17.40102386,0.1417000953,-1.792635698
10.73,17.40622248,0.141775847,-1.79077455
10.74,17.41142826,0.1418512154,-1.788916455
10.75,17.41663681,0.1419262301,-1.787066816
10.76,17.4218621,0.1420008026,-1.78520964
10.77,17.42709053,0.1420750218,-1.78336102
10.78,17.43232687,0.1421488584,-1.781515656
10.79,17.43757598,0.1422222737,-1.779668319
10.8,17.44283339,0.1422953025,-1.777824369
10.81,17.44809925,0.1423679455,-1.775983855
10.82,17.45337371,0.1424402032,-1.774146827
10.83,17.45866162,0.1425120283,-1.772308136
10.84,17.46395839,0.1425834652,-1.770473065
10.85,17.46925929,0.1426545672,-1.768646819
10.86,17.47457879,0.142725177,-1.766813965
10.87,17.47990254,0.1427954539,-1.764990028
10.88,17.48523536,0.1428653462,-1.763169895
10.89,17.49058213,0.1429347932,-1.761348538
10.9,17.49593797,0.1430038539,-1.759531119
10.91,17.50130283,0.1430725299,-1.757717676
10.92,17.50667662,0.1431408231,-1.755908253
10.93,17.51206425,0.1432086638,-1.754097909
10.94,17.51746071,0.1432761207,-1.752291736
10.95,17.52286091,0.1433432712,-1.750494731
10.96,17.52827987,0.1434098901,-1.748692104
10.97,17.53370242,0.143476207,-1.746898748
10.98,17.53913351,0.1435421484,-1.745109775
10.99,17.54457815,0.1436076322,-1.743320394
11,17.55003117,0.143672741,-1.741535572
11.01,17.55549244,0.1437374777,-1.739755377
11.02,17.56096183,0.1438018448,-1.737979867
11.03,17.5664444,0.1438657516,-1.736204341
11.04,17.57193488,0.1439292898,-1.734433665
11.05,17.57743313,0.143992462,-1.732667896
11.06,17.58293903,0.1440552707,-1.730907092
11.07,17.58845245,0.1441177184,-1.729151306
11.08,17.59397324,0.1441798076,-1.727400593
11.09,17.59950656,0.1442414342,-1.725650406
11.1,17.60504701,0.144302703,-1.723905456
11.11,17.61059443,0.1443636165,-1.722165799
11.12,17.61614869,0.1444241769,-1.72043149
11.13,17.62171502,0.1444842719,-1.718698088
11.14,17.62728803,0.1445440142,-1.716970195
11.15,17.63286761,0.1446034064,-1.715247839
11.16,17.63845367,0.1446624506,-1.713531041
11.17,17.64404612,0.144721149,-1.711819819
11.18,17.64964489,0.1447795037,-1.710114191
11.19,17.65525524,0.1448373895,-1.708409847
11.2,17.66087171,0.1448949315,-1.706711192
11.21,17.66649415,0.1449521317,-1.705018246
11.22,17.67212245,0.1450089921,-1.703331032
11.23,17.6777619,0.1450653796,-1.701645362
11.24,17.68340693,0.1451214273,-1.699965532
11.25,17.68905194,0.1451772766,-1.698295716
11.26,17.69471315,0.1452325115,-1.696623467
11.27,17.70037407,0.1452875516,-1.694961266
11.28,17.70604005,0.1453422592,-1.693304975
11.29,17.71171648,0.1453964889,-1.691650572
11.3,17.71739787,0.1454503855,-1.690002172
11.31,17.7230842,0.1455039507,-1.688359793
11.32,17.72877549,0.1455571858,-1.686723447
11.33,17.73447724,0.1456099378,-1.685089219
11.34,17.74018402,0.1456623589,-1.683461096
11.35,17.74589034,0.1457146095,-1.681842953
11.36,17.75161279,0.1457662147,-1.68022323
11.37,17.75733494,0.145817652,-1.678613532
11.38,17.76306241,0.145868764,-1.67701001
11.39,17.76880082,0.1459193857,-1.675408905
11.4,17.77454478,0.145969681,-1.673813989
11.41,17.78029442,0.146019651,-1.672225229
11.42,17.78604993,0.1460692966,-1.670642591
11.43,17.79181703,0.1461184452,-1.669062419
11.44,17.79759038,0.1461672679,-1.667488358
11.45,17.80336467,0.1462159436,-1.665923931
11.46,17.8091569,0.1462639398,-1.664358406
11.47,17.81495055,0.1463117905,-1.662802423
11.48,17.82075141,0.1463593186,-1.661252373
11.49,17.82656528,0.1464063396,-1.659704756
11.5,17.83238675,0.1464530355,-1.658163022
11.51,17.83821604,0.1464994067,-1.656627121
11.52,17.84405343,0.1465454537,-1.655097003
11.53,17.84990491,0.1465909837,-1.653569283
11.54,17.85576512,0.146636186,-1.652047305
11.55,17.8616344,0.1466810602,-1.650531025
11.56,17.86751312,0.1467256061,-1.649020401
11.57,17.87340162,0.1467698234,-1.647515384
11.58,17.87930026,0.1468137119,-1.646015928
11.59,17.88521529,0.146857067,-1.644518814
11.6,17.89114131,0.1469000885,-1.643027228
11.61,17.89707872,0.1469427759,-1.641541141
11.62,17.90302789,0.1469851284,-1.640060527
11.63,17.90899517,0.1470269339,-1.638582293
11.64,17.91497504,0.1470683993,-1.637109519
11.65,17.92096179,0.1471097395,-1.635645191
11.66,17.92697402,0.1471503069,-1.634180249
11.67,17.93299382,0.1471907468,-1.63272373
11.68,17.9390276,0.1472308423,-1.631272606
11.69,17.94508194,0.1472703691,-1.629823947
11.7,17.95115101,0.1473095451,-1.628380685
11.71,17.95723512,0.1473483692,-1.626942769
11.72,17.96333451,0.1473868403,-1.625510145
11.73,17.96945587,0.1474247267,-1.624079946
11.74,17.97559316,0.1474622544,-1.622654981
11.75,17.98174011,0.1474996569,-1.621237958
11.76,17.98791666,0.1475362287,-1.619820541
11.77,17.99410348,0.1475726727,-1.618410954
11.78,18.0003074,0.1476087525,-1.61700638
11.79,18.00653544,0.1476442242,-1.615604106
11.8,18.01278119,0.1476793252,-1.614206795
11.81,18.01904491,0.1477140546,-1.612814413
11.82,18.02532685,0.1477484115,-1.61142693
11.83,18.03163421,0.1477821442,-1.610041746
11.84,18.03796026,0.1478154985,-1.608661453
11.85,18.044298,0.1478487286,-1.60728855
11.86,18.05066891,0.1478810693,-1.605915439
11.87,18.05705165,0.1479132847,-1.604549661
11.88,18.0634534,0.1479451194,-1.603188669
11.89,18.06988152,0.1479763102,-1.601830002
11.9,18.07632873,0.1480071158,-1.600476099
11.91,18.08279508,0.148037536,-1.599126936
11.92,18.08928062,0.1480675708,-1.597782505
11.93,18.0957931,0.1480969494,-1.596440464
11.94,18.10232504,0.1481259392,-1.595103193
11.95,18.10886867,0.1481548159,-1.593772981
11.96,18.11544729,0.1481827551,-1.592442938
11.97,18.12203741,0.1482105834,-1.591119939
11.98,18.12864663,0.148238027,-1.589801694
11.99,18.13528273,0.1482648045,-1.588485995
12,18.14193758,0.1482911967,-1.587175081
12.01,18.14861093,0.1483172052,-1.58586894
12.02,18.15530252,0.1483428317,-1.584567561
12.03,18.16202037,0.1483677865,-1.58326882
12.04,18.16875607,0.1483923585,-1.581974854
12.05,18.17550939,0.148416549,-1.580685642
12.06,18.18228012,0.1484403593,-1.579401166
12.07,18.18906803,0.1484637909,-1.578121405
12.08,18.19587297,0.1484868455,-1.576846347
12.09,18.20270336,0.1485092215,-1.575574011
12.1,18.20955057,0.1485312196,-1.574306426
12.11,18.21641446,0.1485528412,-1.573043604
12.12,18.22329495,0.148574088,-1.571785563
12.13,18.23020079,0.1485946508,-1.570530434
12.14,18.23712323,0.148614838,-1.569280178
12.15,18.24406219,0.148634651,-1.568034817
12.16,18.25101761,0.1486540911,-1.566794376
12.17,18.25798946,0.1486731595,-1.565558884
12.18,18.26497773,0.1486918576,-1.564328373
12.19,18.27199151,0.1487098643,-1.563101121
12.2,18.27902174,0.148727499,-1.561878948
12.21,18.28606837,0.1487447629,-1.560661871
12.22,18.2931313,0.1487616572,-1.559449891
12.23,18.30021968,0.1487778536,-1.558241348
12.24,18.30732413,0.1487936791,-1.557037941
12.25,18.31443503,0.1488094682,-1.555841278
12.26,18.32158047,0.1488242218,-1.554646539
12.27,18.32873198,0.1488389413,-1.553458575
12.28,18.33589877,0.1488532948,-1.552275808
12.29,18.34309029,0.1488669427,-1.551096757
12.3,18.35029682,0.1488802233,-1.549923021
12.31,18.3575182,0.1488931378,-1.548754631
12.32,18.36475423,0.1489056874,-1.547591615
12.33,18.37201452,0.148917525,-1.546432588
12.34,18.37928916,0.1489289963,-1.54527905
12.35,18.38656815,0.1489404541,-1.544132407
12.36,18.39388093,0.1489508444,-1.542988609
12.37,18.40119777,0.1489612237,-1.541851791
12.38,18.40852833,0.1489712416,-1.540720617
12.39,18.41588247,0.1489805402,-1.53959385
12.4,18.42325009,0.1489894767,-1.53847284
12.41,18.43063104,0.1489980527,-1.537357621
12.42,18.43802515,0.14900627,-1.536248229
12.43,18.44544244,0.1490137638,-1.535143537
12.44,18.45287263,0.1490208984,-1.534044793
12.45,18.46030535,0.1490280453,-1.532953137
12.46,18.46777112,0.1490340967,-1.531865268
12.47,18.47523898,0.1490401638,-1.530784499
12.48,18.48271892,0.1490458787,-1.529709709
12.49,18.49022106,0.1490508666,-1.528639878
12.5,18.49773488,0.1490555028,-1.527576048
12.51,18.50526015,0.1490597893,-1.526518213
12.52,18.51279667,0.149063728,-1.525466368
12.53,18.52035474,0.1490669373,-1.524419614
12.54,18.52792377,0.1490697998,-1.523378896
12.55,18.53550361,0.1490723175,-1.52234421
12.56,18.54309413,0.1490744925,-1.521315547
12.57,18.55069518,0.1490763264,-1.520292897
12.58,18.55830666,0.1490778213,-1.519276248
12.59,18.5659391,0.149078585,-1.51826486
12.6,18.57358176,0.14907901,-1.51725951
12.61,18.5812345,0.1490790982,-1.516260187
12.62,18.58889713,0.1490788518,-1.515266879
12.63,18.59658023,0.1490778724,-1.514278948
12.64,18.60427292,0.1490765594,-1.51329707
12.65,18.61197501,0.1490749148,-1.51232125
12.66,18.61968633,0.1490729408,-1.51135149
12.67,18.62740672,0.1490706395,-1.510387792
12.68,18.63513594,0.149068013,-1.509430152
12.69,18.64288461,0.1490646529,-1.508478099
12.7,18.65064163,0.149060968,-1.50753216
12.71,18.65840669,0.1490569604,-1.506592351
12.72,18.66617945,0.1490526323,-1.505658697
12.73,18.67397048,0.1490475698,-1.50473087
12.74,18.68176855,0.1490421881,-1.503809311
12.75,18.68956238,0.1490369088,-1.50289435
12.76,18.69738447,0.1490304762,-1.501985097
12.77,18.70520162,0.1490241504,-1.501082509
12.78,18.71302443,0.1490175149,-1.500186316
12.79,18.72086342,0.1490101472,-1.499296366
12.8,18.72870717,0.1490024728,-1.498412956
12.81,18.7365552,0.148994495,-1.497536129
12.82,18.74440703,0.1489862167,-1.496665926
12.83,18.75227314,0.1489772102,-1.49580233
12.84,18.76014211,0.1489679076,-1.494945569
12.85,18.76801346,0.148958313,-1.494095716
12.86,18.77588674,0.1489484303,-1.493252848
12.87,18.78376143,0.1489382634,-1.492417053
12.88,18.79163707,0.1489278161,-1.491588429
12.89,18.79952411,0.1489166529,-1.490767205
12.9,18.8074112,0.1489052146,-1.489953478
12.91,18.81529786,0.1488935052,-1.489147399
12.92,18.8231836,0.1488815288,-1.488349112
12.93,18.83107873,0.1488688453,-1.487558991
12.94,18.83897181,0.1488559014,-1.486776996
12.95,18.84685151,0.148843149,-1.486002946
12.96,18.85474961,0.1488292512,-1.485237845
12.97,18.86263314,0.1488155548,-1.484480868
12.98,18.87051218,0.1488016179,-1.483732355
12.99,18.87839667,0.1487869941,-1.482992763
13,18.8862751,0.1487721389,-1.482261732
13.01,18.89414668,0.1487570584,-1.481539278
13.02,18.90201067,0.148741759,-1.480825414
13.03,18.90987679,0.1487257903,-1.480120728
13.04,18.91773369,0.148709613,-1.479424708
13.05,18.92558056,0.1486932332,-1.478737349
13.06,18.9334166,0.1486766572,-1.478058644
13.07,18.94124105,0.1486598913,-1.477388584
13.08,18.94905319,0.1486429413,-1.476727163
13.09,18.9568625,0.1486253509,-1.476075169
13.1,18.96465804,0.1486075866,-1.475431865
13.11,18.97243913,0.1485896545,-1.47479725
13.12,18.98020507,0.1485715606,-1.474171322
13.13,18.987965,0.148552845,-1.473555022
13.14,18.99570838,0.1485339779,-1.472947497
13.15,19.00343465,0.1485149653,-1.472348764
13.16,19.0111433,0.1484958133,-1.471758829
13.17,19.01883379,0.1484765277,-1.471177693
13.18,19.02650561,0.1484571143,-1.470605348
13.19,19.03416759,0.1484371079,-1.470042945
13.2,19.04180979,0.1484169829,-1.469489408
13.21,19.04943179,0.1483967441,-1.46894474
13.22,19.05703314,0.1483763964,-1.468408929
13.23,19.06462237,0.1483554711,-1.467883248
13.24,19.07218988,0.1483344445,-1.467366441
13.25,19.0797265,0.1483137954,-1.466857112
13.26,19.08725807,0.1482921052,-1.466359353
13.27,19.09475784,0.1482708013,-1.465869037
13.28,19.10223414,0.1482494139,-1.465387525
13.29,19.1096949,0.1482274707,-1.464916331
13.3,19.11713126,0.1482054517,-1.464454012
13.31,19.12454291,0.1481833608,-1.464000567
13.32,19.13192952,0.1481612016,-1.463555989
13.33,19.1392987,0.148138499,-1.463121922
13.34,19.14664204,0.1481157338,-1.46269677
13.35,19.15395163,0.1480933885,-1.4622788
13.36,19.16125035,0.148070028,-1.461873205
13.37,19.16851507,0.1480470934,-1.461474806
13.38,19.17575349,0.1480241078,-1.461085346
13.39,19.18297285,0.1480005925,-1.460706726
13.4,19.19016577,0.1479770299,-1.46033717
13.41,19.19733233,0.1479534224,-1.459976659
13.42,19.20447266,0.1479297724,-1.459625159
13.43,19.2115937,0.1479055994,-1.459284654
13.44,19.21868864,0.1478813872,-1.45895317
13.45,19.22575118,0.1478576208,-1.458628599
13.46,19.23280126,0.1478328513,-1.458317173
13.47,19.23981943,0.1478085303,-1.458012551
13.48,19.24681241,0.1477841758,-1.45771673
13.49,19.25378646,0.1477593039,-1.457431844
13.5,19.26073547,0.1477343996,-1.457155637
13.51,19.26765964,0.1477094634,-1.456888023
13.52,19.27455923,0.147684496,-1.456628926
13.53,19.28144024,0.147659012,-1.456380608
13.54,19.28829709,0.1476334976,-1.456140655
13.55,19.29513017,0.1476079531,-1.45590894
13.56,19.30193979,0.1475823793,-1.455685332
13.57,19.30872625,0.1475567767,-1.455469694
13.58,19.31548979,0.1475311458,-1.45526189
13.59,19.32223571,0.147504999,-1.455064331
13.6,19.32895889,0.1474788239,-1.454874384
13.61,19.33565946,0.1474526206,-1.45469192
13.62,19.34233757,0.1474263887,-1.454516811
13.63,19.34899814,0.1473996384,-1.454351615
13.64,19.35563655,0.1473728581,-1.454193607
13.65,19.36225312,0.1473460471,-1.454042696
13.66,19.36884825,0.1473192048,-1.453898806
13.67,19.3754223,0.1472923302,-1.453761873
13.68,19.38197567,0.1472654225,-1.453631839
13.69,19.38851291,0.1472379896,-1.453511483
13.7,19.39503016,0.1472105211,-1.453397964
13.71,19.40152794,0.1471830159,-1.453291249
13.72,19.40800677,0.1471554727,-1.453191328
13.73,19.41447104,0.1471273973,-1.453101151
13.74,19.42091729,0.1470992806,-1.453017826
13.75,19.4273424,0.1470716148,-1.452938355
13.76,19.43375797,0.1470429181,-1.452871782
13.77,19.44015359,0.1470146698,-1.452809132
13.78,19.44653353,0.146986375,-1.452753453
13.79,19.45290174,0.1469575376,-1.452707889
13.8,19.45925525,0.146928651,-1.452669388
13.81,19.46559453,0.146899715,-1.452637942
13.82,19.47192005,0.146870729,-1.452613538
13.83,19.47823539,0.1468411958,-1.4525994
13.84,19.48453775,0.1468116109,-1.452592357
13.85,19.4908246,0.1467824713,-1.452589123
13.86,19.49710548,0.1467522841,-1.452599534
13.87,19.50337176,0.1467225409,-1.452613683
13.88,19.50962681,0.146692744,-1.452634787
13.89,19.51587369,0.1466623943,-1.452666152
13.9,19.52210965,0.1466319907,-1.452704368
13.91,19.52833475,0.1466015337,-1.452749352
13.92,19.53454893,0.1465710243,-1.452800995
13.93,19.54075466,0.146539963,-1.452862674
13.94,19.54694918,0.1465088504,-1.452930816
13.95,19.55312996,0.1464781887,-1.453001761
13.96,19.5593043,0.1464464754,-1.453086023
13.97,19.56546461,0.1464152161,-1.453172798
13.98,19.57161307,0.1463839117,-1.453265468
13.99,19.57775164,0.1463520623,-1.453367533
14,19.58387767,0.1463201726,-1.453475261
14.01,19.58999088,0.1462882451,-1.453588518
14.02,19.59609102,0.1462562823,-1.453707177
14.03,19.60217987,0.1462237823,-1.453834852
14.04,19.6082551,0.1461912507,-1.453967738
14.05,19.61431653,0.1461586893,-1.454105709
14.06,19.62036398,0.1461261003,-1.454248645
14.07,19.62639727,0.1460934856,-1.454396447
14.08,19.63241624,0.1460608474,-1.454549038
14.09,19.63842235,0.1460276822,-1.454710199
14.1,19.64441373,0.1459944969,-1.454876046
14.11,19.65039026,0.1459612935,-1.4550465
14.12,19.65635184,0.145928074,-1.455221497
14.13,19.66229975,0.145894334,-1.455404918
14.14,19.66823242,0.1458605819,-1.455592846
14.15,19.67414852,0.1458273278,-1.455781269
14.16,19.68005162,0.1457930531,-1.455982002
14.17,19.685938,0.1457592823,-1.456183055
14.18,19.69180883,0.1457255117,-1.456388258
14.19,19.697665,0.1456912369,-1.456601509
14.2,19.70350545,0.1456569687,-1.456818685
14.21,19.7093302,0.1456227104,-1.457039668
14.22,19.71513932,0.1455884652,-1.457264354
14.23,19.72093356,0.145553728,-1.457496748
14.24,19.72671225,0.1455190098,-1.457732705
14.25,19.73247499,0.1454848221,-1.457968009
14.26,19.73822348,0.1454496418,-1.458214911
14.27,19.74395624,0.1454149968,-1.458460959
14.28,19.74967384,0.1453803807,-1.45871017
14.29,19.75537659,0.1453452863,-1.458966616
14.3,19.76106419,0.1453102241,-1.45922605
14.31,19.76673668,0.1452751954,-1.459488367
14.32,19.77239413,0.145240202,-1.459753477
14.33,19.77803665,0.1452047362,-1.46002551
14.34,19.78366424,0.1451693088,-1.460300158
14.35,19.78927713,0.1451344306,-1.460573036
14.36,19.79487495,0.1450985755,-1.460856712
14.37,19.80045809,0.1450632728,-1.461138344
14.38,19.80602643,0.1450280153,-1.461422076
14.39,19.81157955,0.1449922958,-1.461712096
14.4,19.81711769,0.1449566255,-1.462004048
14.41,19.82264077,0.1449210062,-1.462297832
14.42,19.8281488,0.1448854395,-1.462593352
14.43,19.83364123,0.1448494176,-1.462894832
14.44,19.83911878,0.1448134505,-1.463197891
14.45,19.84458245,0.1447780483,-1.463498131
14.46,19.85003014,0.1447416843,-1.463808461
14.47,19.85546445,0.1447058866,-1.464115834
14.48,19.86088486,0.144670147,-1.464424506
14.49,19.86629054,0.1446339572,-1.464738733
14.5,19.87168263,0.1445978274,-1.465054026
14.51,19.87706129,0.1445617589,-1.465370213
14.52,19.8824267,0.1445257528,-1.465687125
14.53,19.88777776,0.1444893015,-1.466008981
14.54,19.89311578,0.1444529151,-1.466331281
14.55,19.89844089,0.1444165946,-1.466653894
14.56,19.90375319,0.1443803406,-1.466976695
14.57,19.90905278,0.1443441533,-1.467299548
14.58,19.91433976,0.1443080329,-1.467622321
14.59,19.91961268,0.1442714705,-1.467949271
14.6,19.92487325,0.1442349742,-1.468275889
14.61,19.93012163,0.1441985429,-1.46860205
14.62,19.93535799,0.1441621752,-1.468927621
14.63,19.9405807,0.144125361,-1.469256856
14.64,19.94579179,0.1440886067,-1.469585212
14.65,19.95099163,0.1440519103,-1.46991254
14.66,19.95618059,0.1440152697,-1.470238714
14.67,19.96135903,0.1439786832,-1.470563603
14.68,19.96652728,0.1439421489,-1.470887068
14.69,19.97168353,0.1439051572,-1.471213349
14.7,19.97683013,0.1438682141,-1.471537929
14.71,19.98196738,0.1438313178,-1.471860677
14.72,19.9870955,0.1437944662,-1.472181463
14.73,19.99221243,0.143757149,-1.47250453
14.74,19.99732062,0.1437198724,-1.472825393
14.75,20.00242268,0.1436831424,-1.473139583
14.76,20.00751159,0.1436454322,-1.473460021
14.77,20.01259473,0.1436082642,-1.473773533
14.78,20.01766979,0.1435711283,-1.47408431
14.79,20.02273431,0.1435335143,-1.4743965
14.8,20.02779083,0.1434959288,-1.474705617
14.81,20.03283942,0.1434583706,-1.47501154
14.82,20.03788013,0.1434208381,-1.475314157
14.83,20.04291027,0.1433828216,-1.475617634
14.84,20.04793242,0.1433448287,-1.47591755
14.85,20.05294927,0.1433073678,-1.476209484
14.86,20.05795217,0.1432689127,-1.476505975
14.87,20.06294927,0.1432309906,-1.476793961
14.88,20.0679374,0.1431930933,-1.477077446
14.89,20.07291321,0.1431547123,-1.477360383
14.9,20.07787918,0.1431163571,-1.477638276
14.91,20.08283496,0.1430780281,-1.477910878
14.92,20.08778017,0.1430397253,-1.47817795
14.93,20.09271132,0.1430009394,-1.478443408
14.94,20.09763115,0.1429621798,-1.47870284
14.95,20.10254255,0.1429239566,-1.478951914
14.96,20.10743529,0.1428847423,-1.479202714
14.97,20.11231886,0.1428460674,-1.479442707
14.98,20.11718966,0.1428074247,-1.479675819
14.99,20.12204382,0.1427683069,-1.47990591
15,20.1268843,0.1427292259,-1.480128765
15.01,20.13171068,0.1426901843,-1.480344239
15.02,20.13652258,0.1426511847,-1.480552236
15.03,20.14131587,0.1426117201,-1.480756596
15.04,20.14609385,0.1425723028,-1.480953255
15.05,20.1508562,0.1425329351,-1.481142145
15.06,20.15560265,0.1424936195,-1.481323227
15.07,20.16033297,0.1424543584,-1.481496469
15.08,20.16504694,0.1424151539,-1.481661845
15.09,20.16974026,0.1423754985,-1.481823131
15.1,20.17441687,0.1423359036,-1.481976492
15.11,20.17907674,0.1422963716,-1.48212195
15.12,20.18371989,0.142256905,-1.482259493
15.13,20.18834189,0.1422169974,-1.482392805
15.14,20.19294702,0.1421771612,-1.482518155
15.15,20.19753527,0.1421373997,-1.482635567
15.16,20.20210665,0.1420977159,-1.482745074
15.17,20.20666123,0.1420581127,-1.482846718
15.18,20.21119906,0.1420185929,-1.482940542
15.19,20.21571534,0.1419786503,-1.48303013
15.2,20.22021486,0.1419387964,-1.48311192
15.21,20.22469771,0.1418990337,-1.483185944
15.22,20.22916402,0.1418593646,-1.483252225
15.23,20.23360873,0.1418192842,-1.48331422
15.24,20.23803685,0.1417793037,-1.483368412
15.25,20.24245367,0.1417399333,-1.483411399
15.26,20.24684361,0.1416996532,-1.483453429
15.27,20.25122251,0.1416599885,-1.483484287
15.28,20.2555853,0.141620434,-1.48350741
15.29,20.25992675,0.1415804858,-1.483526159
15.3,20.26425248,0.1415406536,-1.483537243
15.31,20.26856281,0.1415009402,-1.483540741
15.32,20.2728581,0.1414613482,-1.483536735
15.33,20.27713293,0.1414213746,-1.48352852
15.34,20.28139326,0.141381528,-1.483512888
15.35,20.28563935,0.1413418113,-1.483489908
15.36,20.28987148,0.1413022271,-1.483459652
15.37,20.29408984,0.1412627784,-1.483422175
15.38,20.29829464,0.1412234683,-1.48337755
15.39,20.30248,0.1411837967,-1.483328958
15.4,20.3066521,0.1411442712,-1.483273324
15.41,20.31081113,0.1411048954,-1.483210697
15.42,20.31495723,0.1410656729,-1.483141102
15.43,20.31908422,0.1410261051,-1.483067565
15.44,20.32319842,0.140986699,-1.482987034
15.45,20.32730636,0.1409479598,-1.482896542
15.46,20.33138892,0.1409083869,-1.482805017
15.47,20.33546548,0.1408694892,-1.482703517
15.48,20.33952979,0.1408307693,-1.48259502
15.49,20.34357543,0.1407917315,-1.482482431
15.5,20.34760913,0.1407528805,-1.482362821
15.51,20.3516311,0.1407142202,-1.482236193
15.52,20.35564155,0.140675754,-1.482102547
15.53,20.35963377,0.1406369881,-1.481964668
15.54,20.3636146,0.1405984253,-1.481819664
15.55,20.36758426,0.1405600697,-1.481667505
15.56,20.37154297,0.1405219257,-1.48150816
15.57,20.37549091,0.1404839973,-1.48134159
15.58,20.37942833,0.1404462888,-1.481167771
15.59,20.38334841,0.1404083098,-1.480989374
15.6,20.38725845,0.1403705597,-1.480803684
15.61,20.39115878,0.1403330418,-1.480610726
15.62,20.39504968,0.1402957593,-1.480410506
15.63,20.39892412,0.1402582239,-1.480205543
15.64,20.40278961,0.1402209323,-1.479993078
15.65,20.40664649,0.140183888,-1.479773005
15.66,20.41049511,0.1401470945,-1.479545229
15.67,20.41433581,0.140110556,-1.479309648
15.68,20.41816895,0.1400742767,-1.479066195
15.69,20.42198741,0.140037773,-1.47881729
15.7,20.42579894,0.1400015387,-1.478560399
15.71,20.42960379,0.1399655778,-1.478295506
15.72,20.43340228,0.1399298944,-1.478022596
15.73,20.43718712,0.1398940074,-1.477744045
15.74,20.44096619,0.139858408,-1.477457431
15.75,20.44474745,0.1398235841,-1.477160401
15.76,20.4485081,0.1397880904,-1.476859896
15.77,20.45227156,0.1397533816,-1.47654893
15.78,20.45603047,0.1397189784,-1.476229805
15.79,20.45977737,0.1396844049,-1.475904711
15.8,20.46352035,0.1396501468,-1.47557131
15.81,20.46725974,0.1396162081,-1.475229538
15.82,20.47099579,0.1395825925,-1.474879323
15.83,20.4747208,0.1395488273,-1.474522736
15.84,20.47844279,0.1395153948,-1.47415753
15.85,20.48216984,0.1394827733,-1.473781564
15.86,20.48587823,0.1394495412,-1.473401016
15.87,20.4895919,0.1394171253,-1.473009589
15.88,20.49330297,0.1393850536,-1.472609266
15.89,20.49700345,0.1393528576,-1.472201935
15.9,20.50070127,0.139321013,-1.471785488
15.91,20.50439632,0.1392895229,-1.471359849
15.92,20.50808835,0.139258391,-1.470924909
15.93,20.51176895,0.139227154,-1.470482385
15.94,20.51544583,0.139196285,-1.470030209
15.95,20.51912676,0.1391662532,-1.469566451
15.96,20.52278682,0.1391356673,-1.469096419
15.97,20.52644996,0.1391059262,-1.468614523
15.98,20.5301075,0.1390765681,-1.468122385
15.99,20.53375063,0.1390471352,-1.467621516
16,20.53738714,0.1390180932,-1.467109987
16.01,20.54101656,0.1389894448,-1.466587623
16.02,20.54463843,0.1389611923,-1.466054254
16.03,20.54824388,0.1389328814,-1.465511298
16.04,20.55184089,0.1389049737,-1.464956991
16.05,20.5554291,0.1388774714,-1.464391188
16.06,20.55900812,0.1388503774,-1.463813748
16.07,20.56257763,0.1388236945,-1.463224559
16.08,20.56613739,0.1387974259,-1.462623544
16.09,20.56967849,0.1387711245,-1.462011993
16.1,20.57320937,0.1387452453,-1.461388428
16.11,20.57672984,0.1387197908,-1.460752801
16.12,20.58023969,0.1386947631,-1.460105072
16.13,20.58372978,0.1386697189,-1.459446414
16.14,20.58720862,0.1386451076,-1.458775502
16.15,20.59068482,0.1386213737,-1.458091162
16.16,20.59413111,0.1385971905,-1.457396769
16.17,20.59757397,0.1385738884,-1.456688892
16.18,20.60100412,0.138551026,-1.45596867
16.19,20.60441207,0.1385281667,-1.455237097
16.2,20.6078066,0.1385057529,-1.454493117
16.21,20.61118743,0.1384837865,-1.453736729
16.22,20.6145543,0.1384622688,-1.452967939
16.23,20.61789753,0.1384407683,-1.452187634
16.24,20.62122614,0.1384197222,-1.451394989
16.25,20.62454937,0.1383995629,-1.450589328
16.26,20.6278385,0.1383789997,-1.449773074
16.27,20.63112179,0.1383593259,-1.448943989
16.28,20.63438953,0.1383401123,-1.448102971
16.29,20.63763177,0.1383209347,-1.447250784
16.3,20.64085794,0.1383022225,-1.44638685
16.31,20.6440679,0.1382839769,-1.445511297
16.32,20.64726156,0.1382661989,-1.444624262
16.33,20.65042878,0.138248469,-1.443726378
16.34,20.65357928,0.1382312116,-1.442817213
16.35,20.65671285,0.1382144277,-1.441896915
16.36,20.65982924,0.1381981184,-1.440965639
16.37,20.66292827,0.1381822845,-1.440023548
16.38,20.66600974,0.1381669272,-1.43907083
16.39,20.66906307,0.1381516344,-1.438108004
16.4,20.67209825,0.1381368229,-1.437134902
16.41,20.67511503,0.1381224934,-1.436151745
16.42,20.67811313,0.138108647,-1.435158758
16.43,20.68108166,0.1380948765,-1.434156377
16.44,20.68403086,0.138081593,-1.433144566
16.45,20.68697126,0.1380692023,-1.4321234
16.46,20.68987018,0.1380564889,-1.431093574
16.47,20.6927597,0.138044669,-1.430054862
16.48,20.69562875,0.1380333376,-1.429007662
16.49,20.69846595,0.1380220945,-1.42795227
16.5,20.70128202,0.1380113423,-1.426888818
16.51,20.70407679,0.1380010808,-1.425817575
16.52,20.70685015,0.1379913096,-1.424738816
16.53,20.70959053,0.1379816335,-1.423652783
16.54,20.71230895,0.1379724495,-1.422559731
16.55,20.71500524,0.1379637568,-1.421459931
16.56,20.71767927,0.1379555548,-1.4203536
16.57,20.72033083,0.1379478436,-1.419240753
16.58,20.72295968,0.1379406233,-1.418121347
16.59,20.72555376,0.1379335066,-1.416995202
16.6,20.72812457,0.1379268833,-1.41586234
16.61,20.73067191,0.1379207533,-1.414722679
16.62,20.73319558,0.137915117,-1.413576142
16.63,20.73568316,0.1379095922,-1.41242244
16.64,20.73814649,0.1379045637,-1.411261662
16.65,20.74059776,0.1379004108,-1.410093973
16.66,20.74299963,0.137895995,-1.408918559
16.67,20.74538909,0.1378924549,-1.407736094
16.68,20.74775362,0.1378894103,-1.406546258
16.69,20.75008045,0.137886486,-1.405348646
16.7,20.75238213,0.1378840577,-1.404143461
16.71,20.75465871,0.1378821238,-1.402930614
16.72,20.75691024,0.1378806822,-1.401710006
16.73,20.75912373,0.1378793611,-1.400481128
16.74,20.76131224,0.1378785296,-1.399244259
16.75,20.76348914,0.1378785521,-1.397999778
16.76,20.76561482,0.1378783252,-1.396746217
16.77,20.76772913,0.1378789472,-1.395484866
16.78,20.76981891,0.1378800487,-1.394215181
16.79,20.77187053,0.1378812655,-1.392936495
16.8,20.77389736,0.1378829596,-1.391649181
16.81,20.77589936,0.1378851288,-1.39035311
16.82,20.77787651,0.1378877708,-1.389048162
16.83,20.77981484,0.137890526,-1.387733571
16.84,20.78172808,0.1378937518,-1.386409837
16.85,20.78361619,0.1378974461,-1.38507686
16.86,20.7854791,0.1379016069,-1.383734552
16.87,20.78731677,0.1379062316,-1.382382831
16.88,20.78912915,0.1379113182,-1.381021618
16.89,20.79090177,0.1379165135,-1.379650038
16.9,20.79264897,0.1379221676,-1.37826877
16.91,20.79437085,0.1379282775,-1.376877742
16.92,20.79606758,0.1379348404,-1.375476888
16.93,20.79772454,0.1379415071,-1.37406525
16.94,20.7993566,0.1379486226,-1.372643667
16.95,20.80097898,0.1379565275,-1.371213082
16.96,20.80254703,0.1379641863,-1.369770577
16.97,20.8041059,0.1379726276,-1.36831904
16.98,20.80564088,0.1379815043,-1.366857534
16.99,20.80713688,0.1379904742,-1.365384998
17,20.80860923,0.1379998763,-1.363902461
17.01,20.81005818,0.1380097076,-1.362409951
17.02,20.81148397,0.1380199654,-1.360907519
17.03,20.81287129,0.1380303107,-1.359394039
17.04,20.81423586,0.138041078,-1.357870712
17.05,20.81557796,0.1380522639,-1.356337649
17.06,20.81689787,0.138063865,-1.354794965
17.07,20.81819583,0.1380758777,-1.353242777
17.08,20.81947207,0.1380882987,-1.351681212
17.09,20.8207107,0.1381007946,-1.350109072
17.1,20.82192778,0.1381136941,-1.348527789
17.11,20.82312348,0.1381269935,-1.346937504
17.12,20.82429796,0.1381406893,-1.345338356
17.13,20.82543507,0.1381544519,-1.343729059
17.14,20.82655127,0.1381686058,-1.34211115
17.15,20.82766334,0.1381834716,-1.340486256
17.16,20.82872201,0.1381980723,-1.338850084
17.17,20.82977704,0.1382133776,-1.337207234
17.18,20.83081218,0.1382290592,-1.335556373
17.19,20.83181086,0.1382447923,-1.333896075
17.2,20.83278992,0.1382608959,-1.332228009
17.21,20.83374958,0.1382773662,-1.330552321
17.22,20.83469007,0.1382941996,-1.328869161
17.23,20.8355946,0.1383110743,-1.327177027
17.24,20.83648036,0.138328306,-1.325477673
17.25,20.83736483,0.1383462071,-1.323772929
17.26,20.83819675,0.1383638246,-1.322057885
17.27,20.839028,0.138382104,-1.320337746
17.28,20.8398417,0.1384007256,-1.318610973
17.29,20.84062066,0.1384193725,-1.316875955
17.3,20.84138247,0.1384383565,-1.315134557
17.31,20.8421274,0.1384576741,-1.313386929
17.32,20.84285572,0.1384773221,-1.311633219
17.33,20.84355002,0.1384969863,-1.309871762
17.34,20.84422814,0.1385169752,-1.308104492
17.35,20.84489033,0.1385372853,-1.306331556
17.36,20.84553687,0.138557913,-1.304553085
17.37,20.846168,0.138578855,-1.302769203
17.38,20.84678397,0.1386001079,-1.300980023
17.39,20.84736702,0.1386213616,-1.299183757
17.4,20.84793527,0.138642921,-1.297382393
17.41,20.84848894,0.1386647831,-1.295576047
17.42,20.84902825,0.1386869446,-1.293764831
17.43,20.84953525,0.138709098,-1.291946918
17.44,20.8500283,0.1387315459,-1.290124354
17.45,20.85052598,0.1387545886,-1.288299232
17.46,20.85097359,0.1387773125,-1.28646576
17.47,20.85142634,0.1388006247,-1.284629966
17.48,20.85186615,0.1388242186,-1.282789994
17.49,20.85227476,0.1388477895,-1.280943954
17.5,20.85267084,0.1388716367,-1.279093953
17.51,20.85305466,0.1388957569,-1.277240108
17.52,20.85342649,0.138920147,-1.275382536
17.53,20.85376789,0.1389445044,-1.273519303
17.54,20.85409772,0.138969126,-1.271652556
17.55,20.85443499,0.1389943072,-1.269784467
17.56,20.85472355,0.1390191498,-1.267908954
17.57,20.85501995,0.1390445462,-1.2660323
17.58,20.85530558,0.139070195,-1.264152539
17.59,20.85556169,0.1390957966,-1.262267677
17.6,20.85580735,0.1391216454,-1.260379893
17.61,20.8560428,0.1391477386,-1.258489318
17.62,20.85626835,0.1391740736,-1.256596096
17.63,20.85646519,0.1392003528,-1.254698252
17.64,20.85665264,0.1392268692,-1.252798013
17.65,20.85685015,0.1392539144,-1.250897625
17.66,20.85700044,0.1392806031,-1.24899085
17.67,20.85716132,0.1393078153,-1.247084176
17.68,20.85731379,0.1393352543,-1.24517559
17.69,20.85743875,0.1393626251,-1.243263043
17.7,20.85755558,0.1393902198,-1.241348654
17.71,20.85766439,0.1394180367,-1.239432391
17.72,20.85776525,0.1394460748,-1.23751421
17.73,20.85783872,0.1394740414,-1.235591887
17.74,20.85790421,0.1395022278,-1.233667465
17.75,20.85798125,0.1395309235,-1.231742996
17.76,20.85801129,0.1395592551,-1.229811898
17.77,20.85805293,0.1395880935,-1.227880535
17.78,20.85808665,0.1396171464,-1.225946631
17.79,20.85809278,0.1396461229,-1.224007881
17.8,20.85809087,0.1396753117,-1.222066329
17.81,20.85808093,0.1397047107,-1.220121846
17.82,20.85806294,0.1397343181,-1.218174309
17.83,20.85801708,0.1397638435,-1.216221391
17.84,20.85796303,0.1397935737,-1.214265185
17.85,20.85790077,0.1398235063,-1.212305598
17.86,20.85783029,0.1398536392,-1.210342545
17.87,20.85775158,0.1398839698,-1.208375948
17.88,20.85766468,0.1399144957,-1.206405732
17.89,20.85754965,0.1399449277,-1.204429566
17.9,20.85742646,0.1399755508,-1.202449602
17.91,20.85729512,0.1400063626,-1.200465761
17.92,20.85715571,0.1400373615,-1.198478
17.93,20.85698815,0.14006826,-1.196483989
17.94,20.85681257,0.140099343,-1.19448596
17.95,20.85664921,0.1401308943,-1.192486169
17.96,20.85643757,0.1401620568,-1.190477631
17.97,20.85623834,0.1401936851,-1.188467218
17.98,20.85603145,0.1402254924,-1.186452563
17.99,20.85579672,0.140257193,-1.184431271
18,20.85555457,0.1402890706,-1.182405669
18.01,20.85530513,0.1403211241,-1.180375759
18.02,20.85504852,0.1403533525,-1.178341534
18.03,20.8547644,0.1403854713,-1.176300592
18.04,20.85447328,0.1404177635,-1.174255298
18.05,20.85417527,0.1404502283,-1.17220564
18.06,20.85387048,0.1404828651,-1.170151583
18.07,20.85355901,0.1405156735,-1.16809309
18.08,20.85324097,0.1405486529,-1.166030117
18.09,20.85289586,0.1405815199,-1.163960168
18.1,20.85254441,0.1406145571,-1.161885619
18.11,20.85218674,0.1406477638,-1.159806414
18.12,20.85182301,0.1406811396,-1.157722495
18.13,20.85143262,0.1407144019,-1.155631299
18.14,20.85103635,0.1407478323,-1.153535237
18.15,20.85065508,0.140781712,-1.151436762
18.16,20.85022652,0.1408151957,-1.149328239
18.17,20.84981313,0.1408491274,-1.147217169
18.18,20.8493942,0.1408832248,-1.145100964
18.19,20.84894893,0.1409172062,-1.142976993
18.2,20.8484982,0.1409513521,-1.140847739
18.21,20.84804205,0.140985662,-1.138713135
18.22,20.84758051,0.1410201353,-1.136573119
18.23,20.84709257,0.1410544911,-1.134425003
18.24,20.84659915,0.1410890096,-1.132271338
18.25,20.84612131,0.1411239706,-1.130114749
18.26,20.84559592,0.141158534,-1.127947269
18.27,20.84508617,0.1411935401,-1.125776846
18.28,20.84457108,0.1412287087,-1.123600824
18.29,20.84402956,0.1412637604,-1.121416491
18.3,20.84348281,0.1412989749,-1.119226529
18.31,20.84293094,0.1413343519,-1.117030943
18.32,20.84237405,0.1413698913,-1.11482974
18.33,20.84179101,0.1414053141,-1.112620187
18.34,20.84120311,0.1414408993,-1.110405051
18.35,20.84061045,0.1414766466,-1.108184367
18.37,20.8394113,0.1415486271,-1.103726503
18.38,20.83880508,0.1415848599,-1.101489404
18.39,20.8381732,0.1416209761,-1.099244081
18.4,20.83753712,0.1416572535,-1.09699339
18.41,20.83689697,0.141693692,-1.094737361
18.42,20.83625286,0.1417302916,-1.092476034
18.43,20.83558345,0.1417667745,-1.090206564
18.45,20.83425503,0.1418405006,-1.085654874
18.46,20.83355324,0.1418771886,-1.083366915
18.48,20.83218268,0.1419515993,-1.078781566
18.49,20.83147105,0.1419887668,-1.076478393
18.51,20.83003879,0.1420635773,-1.071857135
18.55,20.82707714,0.1422142506,-1.062547161
18.56,20.82632547,0.1422522371,-1.060207001`
