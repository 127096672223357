export default `age,mu,sigma,nu
2,16.26054329,0.07132282458,-1.636849607
2.01,16.25885052,0.07133390261,-1.638405877
2.02,16.25714931,0.07134503763,-1.63996991
2.03,16.25543967,0.07135622968,-1.641541704
2.04,16.25372162,0.0713674788,-1.643121262
2.05,16.25199517,0.071378785,-1.644708584
2.06,16.25026034,0.07139014834,-1.64630367
2.07,16.24851715,0.07140156885,-1.647906521
2.08,16.24676562,0.07141304656,-1.649517138
2.09,16.24500578,0.07142458151,-1.651135521
2.1,16.24323765,0.07143617375,-1.652761672
2.11,16.24146126,0.0714478233,-1.65439559
2.12,16.23967664,0.07145953021,-1.656037277
2.13,16.23788384,0.0714712945,-1.657686733
2.14,16.23608287,0.07148311622,-1.659343958
2.15,16.23427378,0.07149499541,-1.661008955
2.16,16.2324566,0.07150693212,-1.662681722
2.17,16.23063137,0.07151892638,-1.664362261
2.18,16.22879813,0.07153097824,-1.666050574
2.19,16.22695692,0.07154308775,-1.667746661
2.2,16.22510779,0.07155525496,-1.669450523
2.21,16.22325078,0.07156747992,-1.671162162
2.22,16.22138592,0.07157976267,-1.672881578
2.23,16.21951327,0.07159210327,-1.674608772
2.24,16.21763286,0.07160450179,-1.676343744
2.25,16.21574475,0.07161695828,-1.678086496
2.26,16.21384897,0.07162947283,-1.679837028
2.27,16.21194558,0.0716420455,-1.68159534
2.28,16.21003463,0.07165467638,-1.683361434
2.29,16.20811616,0.07166736556,-1.685135309
2.3,16.20619022,0.07168011313,-1.686916966
2.31,16.20425686,0.07169291917,-1.688706405
2.32,16.20231614,0.07170578379,-1.690503627
2.33,16.20036811,0.07171870708,-1.692308632
2.34,16.19841282,0.07173168914,-1.694121421
2.35,16.19645033,0.07174473007,-1.695941993
2.36,16.19448069,0.07175783,-1.697770348
2.37,16.19250396,0.07177098905,-1.699606487
2.38,16.19052019,0.07178420735,-1.70145041
2.39,16.18852944,0.07179748504,-1.703302116
2.4,16.18653177,0.07181082224,-1.705161605
2.41,16.18452722,0.07182421912,-1.707028877
2.42,16.18251586,0.07183767581,-1.708903931
2.43,16.18049775,0.07185119246,-1.710786767
2.44,16.17847293,0.07186476924,-1.712677385
2.45,16.17644148,0.07187840632,-1.714575784
2.46,16.17440345,0.07189210387,-1.716481963
2.47,16.17235889,0.07190586209,-1.718395922
2.48,16.17030787,0.07191968117,-1.720317659
2.49,16.16825044,0.07193356131,-1.722247173
2.5,16.16618667,0.0719475027,-1.724184463
2.51,16.16411662,0.07196150555,-1.726129527
2.52,16.16204034,0.07197557007,-1.728082363
2.53,16.1599579,0.07198969649,-1.73004297
2.54,16.15786936,0.07200388501,-1.732011345
2.55,16.15577479,0.07201813587,-1.733987487
2.56,16.15367425,0.0720324493,-1.735971393
2.57,16.15156779,0.07204682553,-1.737963062
2.58,16.14945549,0.0720612648,-1.739962491
2.59,16.14733741,0.07207576735,-1.741969678
2.6,16.14521361,0.07209033344,-1.743984621
2.61,16.14308416,0.07210496333,-1.746007318
2.62,16.14094913,0.07211965729,-1.748037765
2.63,16.13880857,0.07213441559,-1.75007596
2.64,16.13666255,0.07214923852,-1.752121901
2.65,16.13451115,0.07216412637,-1.754175586
2.66,16.13235441,0.07217907945,-1.756237011
2.67,16.13019242,0.07219409807,-1.758306174
2.68,16.12802522,0.07220918255,-1.760383072
2.69,16.1258529,0.07222433322,-1.762467704
2.7,16.12367551,0.07223955043,-1.764560067
2.71,16.12149312,0.07225483454,-1.766660158
2.72,16.11930579,0.0722701859,-1.768767977
2.73,16.11711361,0.07228560489,-1.77088352
2.74,16.11491662,0.07230109187,-1.773006786
2.75,16.11271491,0.07231664722,-1.775137771
2.76,16.11050854,0.07233227134,-1.777276475
2.77,16.10829758,0.07234796462,-1.779422893
2.78,16.10608209,0.07236372745,-1.781577025
2.79,16.10386216,0.07237956024,-1.783738867
2.8,16.10163784,0.07239546343,-1.785908418
2.81,16.09940921,0.07241143745,-1.788085675
2.82,16.09717635,0.07242748275,-1.790270636
2.83,16.09493933,0.07244359981,-1.792463299
2.84,16.09269821,0.0724597891,-1.794663662
2.85,16.09045307,0.07247605114,-1.796871724
2.86,16.08820398,0.07249238646,-1.799087483
2.87,16.08595101,0.07250879561,-1.801310937
2.88,16.08369422,0.07252527917,-1.803542084
2.89,16.08143368,0.0725418377,-1.805780921
2.9,16.07916947,0.0725584718,-1.808027449
2.91,16.07690165,0.07257518206,-1.810281664
2.92,16.07463029,0.07259196909,-1.812543564
2.93,16.07235546,0.07260883351,-1.814813149
2.94,16.07007722,0.07262577594,-1.817090417
2.95,16.06779565,0.07264279703,-1.819375365
2.96,16.06551081,0.07265989743,-1.821667994
2.97,16.06322276,0.07267707778,-1.823968302
2.98,16.06093158,0.07269433877,-1.826276286
2.99,16.05863732,0.07271168106,-1.828591945
3,16.05634005,0.07272910535,-1.830915275
3.01,16.05403983,0.07274661235,-1.833246272
3.02,16.05173671,0.0727642028,-1.835584931
3.03,16.04943077,0.07278187744,-1.837931246
3.04,16.04712205,0.07279963702,-1.840285214
3.05,16.04481061,0.07281748229,-1.842646827
3.06,16.04249652,0.072835414,-1.845016081
3.07,16.04017982,0.07285343294,-1.847392969
3.08,16.03786058,0.07287153988,-1.849777485
3.09,16.03553885,0.07288973562,-1.852169623
3.1,16.03321469,0.07290802098,-1.854569378
3.11,16.03088817,0.07292639677,-1.856976741
3.12,16.02855934,0.07294486383,-1.859391707
3.13,16.02622827,0.07296342302,-1.861814267
3.14,16.02389501,0.07298207521,-1.864244414
3.15,16.02155964,0.07300082129,-1.866682138
3.16,16.01922221,0.07301966215,-1.869127433
3.17,16.01688279,0.07303859873,-1.871580289
3.18,16.01454144,0.07305763194,-1.874040699
3.19,16.01219823,0.07307676272,-1.876508653
3.2,16.00985321,0.07309599205,-1.878984141
3.21,16.00750644,0.07311532088,-1.881467156
3.22,16.005158,0.07313475022,-1.883957687
3.23,16.00280794,0.07315428106,-1.886455726
3.24,16.00045634,0.07317391442,-1.888961262
3.25,15.99810324,0.07319365131,-1.891474286
3.26,15.99574873,0.07321349277,-1.89399479
3.27,15.99339287,0.07323343984,-1.896522762
3.28,15.99103571,0.07325349358,-1.899058193
3.29,15.98867733,0.07327365506,-1.901601073
3.3,15.98631779,0.07329392535,-1.904151391
3.31,15.98395716,0.07331430554,-1.906709136
3.32,15.98159549,0.07333479677,-1.909274299
3.33,15.97923285,0.07335540018,-1.91184687
3.34,15.97686931,0.07337611693,-1.914426836
3.35,15.97450494,0.07339694821,-1.917014187
3.36,15.97213979,0.07341789521,-1.919608911
3.37,15.96977393,0.07343895916,-1.922210997
3.38,15.96740744,0.0734601413,-1.924820434
3.39,15.96504038,0.07348144289,-1.927437208
3.4,15.96267283,0.07350286521,-1.930061308
3.41,15.96030485,0.07352440954,-1.93269272
3.42,15.95793652,0.0735460772,-1.935331431
3.43,15.95556791,0.07356786952,-1.937977427
3.44,15.95319909,0.07358978784,-1.940630696
3.45,15.95083015,0.0736118335,-1.943291224
3.46,15.94846115,0.0736340078,-1.945958996
3.47,15.94609217,0.07365631202,-1.948633997
3.48,15.94372329,0.07367874743,-1.951316214
3.49,15.94135459,0.07370131527,-1.954005632
3.5,15.93898618,0.07372401678,-1.956702237
3.51,15.93661814,0.07374685312,-1.959406017
3.52,15.9342506,0.0737698254,-1.962116957
3.53,15.93188366,0.07379293466,-1.964835042
3.54,15.92951743,0.07381618182,-1.967560256
3.55,15.92715205,0.0738395678,-1.970292579
3.56,15.92478762,0.07386309349,-1.973031993
3.57,15.92242428,0.07388675971,-1.975778481
3.58,15.92006217,0.07391056723,-1.978532024
3.59,15.91770142,0.07393451671,-1.981292602
3.6,15.91534217,0.07395860876,-1.984060197
3.61,15.91298457,0.07398284398,-1.986834786
3.62,15.91062876,0.07400722293,-1.989616349
3.63,15.9082749,0.07403174617,-1.992404863
3.64,15.90592312,0.07405641423,-1.995200305
3.65,15.90357358,0.07408122763,-1.998002649
3.66,15.90122642,0.07410618686,-2.000811872
3.67,15.8988818,0.07413129241,-2.003627944
3.68,15.89653985,0.07415654478,-2.006450836
3.69,15.89420074,0.07418194443,-2.009280514
3.7,15.89186461,0.0742074918,-2.012116948
3.71,15.88953162,0.07423318736,-2.014960106
3.72,15.88720193,0.07425903163,-2.01780996
3.73,15.88487568,0.07428502515,-2.020666479
3.74,15.88255303,0.07431116847,-2.023529636
3.75,15.88023411,0.07433746213,-2.026399399
3.76,15.87791908,0.07436390671,-2.029275737
3.77,15.87560808,0.07439050276,-2.032158617
3.78,15.87330125,0.07441725085,-2.035048008
3.79,15.87099874,0.07444415153,-2.037943875
3.8,15.86870068,0.07447120535,-2.040846186
3.81,15.86640722,0.07449841288,-2.043754908
3.82,15.8641185,0.07452577469,-2.046670009
3.83,15.86183467,0.0745532914,-2.049591456
3.84,15.85955586,0.07458096363,-2.052519216
3.85,15.8572822,0.07460879199,-2.055453256
3.86,15.85501384,0.07463677713,-2.058393541
3.87,15.85275091,0.0746649197,-2.061340038
3.88,15.85049356,0.07469322034,-2.064292713
3.89,15.84824192,0.07472167971,-2.067251531
3.9,15.84599613,0.07475029843,-2.070216459
3.91,15.84375633,0.07477907714,-2.073187462
3.92,15.84152267,0.07480801648,-2.076164505
3.93,15.83929529,0.07483711713,-2.079147553
3.94,15.83707432,0.07486637975,-2.082136568
3.95,15.8348599,0.07489580506,-2.085131514
3.96,15.83265218,0.07492539376,-2.088132353
3.97,15.83045129,0.07495514658,-2.091139045
3.98,15.82825739,0.07498506429,-2.094151552
3.99,15.8260706,0.07501514774,-2.097169835
4,15.82389107,0.0750453978,-2.100193852
4.01,15.82171893,0.07507581534,-2.103223562
4.02,15.81955429,0.07510640127,-2.106258921
4.03,15.81739726,0.07513715647,-2.109299886
4.04,15.81524797,0.07516808183,-2.112346409
4.05,15.81310654,0.07519917821,-2.115398445
4.06,15.81097308,0.07523044644,-2.118455946
4.07,15.8088477,0.07526188736,-2.121518862
4.08,15.80673051,0.07529350181,-2.124587142
4.09,15.80462163,0.07532529064,-2.127660734
4.1,15.80252116,0.07535725468,-2.130739584
4.11,15.8004292,0.07538939478,-2.13382364
4.12,15.79834586,0.07542171181,-2.136912848
4.13,15.79627124,0.07545420661,-2.140007152
4.14,15.79420545,0.07548688011,-2.143106496
4.15,15.79214859,0.07551973324,-2.146210823
4.16,15.79010075,0.07555276697,-2.149320077
4.17,15.78806203,0.07558598228,-2.152434202
4.18,15.78603254,0.07561938016,-2.155553138
4.19,15.78401237,0.0756529616,-2.158676826
4.2,15.78200162,0.07568672758,-2.161805208
4.21,15.78000039,0.07572067908,-2.164938223
4.22,15.77800878,0.07575481709,-2.168075808
4.23,15.7760269,0.07578914257,-2.171217903
4.24,15.77405486,0.07582365652,-2.174364443
4.25,15.77209275,0.0758583599,-2.177515367
4.26,15.77014069,0.07589325368,-2.180670608
4.27,15.76819879,0.07592833882,-2.183830101
4.28,15.76626714,0.07596361628,-2.186993781
4.29,15.76434584,0.07599908706,-2.190161582
4.3,15.76243501,0.07603475214,-2.193333435
4.31,15.76053473,0.07607061255,-2.196509276
4.32,15.75864511,0.07610666929,-2.199689036
4.33,15.75676625,0.07614292339,-2.202872645
4.34,15.75489826,0.07617937589,-2.206060036
4.35,15.75304123,0.07621602782,-2.209251137
4.36,15.75119526,0.07625288022,-2.212445876
4.37,15.74936046,0.07628993412,-2.21564418
4.38,15.74753693,0.07632719059,-2.218845974
4.39,15.74572478,0.07636465066,-2.222051184
4.4,15.7439241,0.07640231542,-2.225259735
4.41,15.742135,0.07644018592,-2.228471551
4.42,15.74035758,0.07647826327,-2.231686556
4.43,15.73859194,0.07651654855,-2.234904675
4.44,15.73683819,0.07655504281,-2.238125831
4.45,15.73509643,0.07659374716,-2.241349945
4.46,15.73336678,0.07663266267,-2.244576941
4.47,15.73164932,0.07667179041,-2.247806741
4.48,15.72994416,0.07671113144,-2.251039265
4.49,15.72825142,0.07675068685,-2.254274434
4.5,15.72657118,0.07679045775,-2.257512168
4.51,15.72490355,0.07683044525,-2.260752386
4.52,15.72324865,0.07687065047,-2.263995007
4.53,15.72160656,0.07691107454,-2.267239951
4.54,15.7199774,0.0769517186,-2.270487133
4.55,15.71836128,0.0769925838,-2.273736472
4.56,15.71675828,0.07703367132,-2.276987884
4.57,15.7151685,0.07707498234,-2.280241282
4.58,15.71359206,0.07711651808,-2.283496582
4.59,15.71202903,0.07715827974,-2.286753696
4.6,15.71047951,0.07720026858,-2.290012539
4.61,15.70894361,0.07724248587,-2.293273022
4.62,15.70742139,0.07728493289,-2.296535054
4.63,15.70591297,0.07732761098,-2.299798545
4.64,15.70441842,0.07737052144,-2.303063402
4.65,15.70293784,0.07741366561,-2.306329535
4.66,15.70147132,0.07745704481,-2.30959685
4.67,15.70001894,0.0775006604,-2.312865254
4.68,15.69858081,0.07754451372,-2.316134652
4.69,15.697157,0.07758860614,-2.319404948
4.7,15.6957476,0.07763293905,-2.322676047
4.71,15.69435271,0.07767751381,-2.325947853
4.72,15.6929724,0.07772233183,-2.329220268
4.73,15.69160677,0.07776739451,-2.332493195
4.74,15.69025588,0.07781270329,-2.335766535
4.75,15.68891984,0.07785825964,-2.339040192
4.76,15.6875987,0.07790406505,-2.342314067
4.77,15.68629257,0.07795012102,-2.345588063
4.78,15.6850015,0.07799642904,-2.348862079
4.79,15.68372559,0.07804299061,-2.352136015
4.8,15.6824649,0.07808980726,-2.355409769
4.81,15.68121953,0.07813688051,-2.35868324
4.82,15.67998954,0.07818421191,-2.361956322
4.83,15.67877502,0.07823180302,-2.365228911
4.84,15.67757604,0.07827965541,-2.368500904
4.85,15.67639267,0.07832777066,-2.371772193
4.86,15.67522498,0.07837615038,-2.375042673
4.87,15.67407306,0.07842479622,-2.378312239
4.88,15.67293695,0.07847370982,-2.381580787
4.89,15.67181674,0.07852289284,-2.384848213
4.9,15.67071248,0.07857234696,-2.38811441
4.91,15.66962425,0.07862207387,-2.39137927
4.92,15.6685521,0.07867207527,-2.394642686
4.93,15.66749612,0.07872235285,-2.397904545
4.94,15.66645635,0.07877290834,-2.401164738
4.95,15.66543288,0.07882374352,-2.404423155
4.96,15.66442576,0.07887486011,-2.407679684
4.97,15.66343506,0.07892625989,-2.410934215
4.98,15.66246085,0.07897794466,-2.41418664
4.99,15.6615032,0.07902991614,-2.417436851
5,15.66056219,0.07908217614,-2.420684745
5.01,15.65963789,0.07913472636,-2.423930219
5.02,15.65873033,0.07918756844,-2.427173168
5.03,15.65783957,0.07924070389,-2.430413494
5.04,15.65696565,0.07929413394,-2.433651096
5.05,15.65610863,0.07934785951,-2.436885871
5.06,15.65526855,0.07940188146,-2.440117718
5.07,15.65444548,0.07945620054,-2.443346537
5.08,15.65363946,0.0795108175,-2.446572222
5.09,15.65285051,0.07956573311,-2.449794659
5.1,15.65207865,0.07962094808,-2.453013738
5.11,15.65132391,0.07967646302,-2.456229352
5.12,15.65058631,0.07973227854,-2.459441394
5.13,15.64986585,0.07978839526,-2.462649757
5.14,15.64916253,0.07984481375,-2.465854332
5.15,15.64847637,0.07990153442,-2.469055012
5.16,15.64780738,0.07995855763,-2.472251685
5.17,15.6471556,0.08001588369,-2.475444242
5.18,15.64652105,0.08007351279,-2.47863257
5.19,15.64590375,0.08013144506,-2.481816558
5.2,15.64530373,0.08018968063,-2.484996093
5.21,15.64472103,0.08024821962,-2.488171065
5.22,15.64415564,0.08030706213,-2.491341361
5.23,15.64360758,0.08036620816,-2.49450687
5.24,15.64307689,0.08042565768,-2.497667482
5.25,15.64256361,0.08048541062,-2.500823086
5.26,15.64206776,0.0805454669,-2.503973574
5.27,15.64158942,0.08060582648,-2.507118835
5.28,15.64112859,0.08066648933,-2.510258758
5.29,15.64068532,0.08072745553,-2.513393229
5.3,15.64025963,0.08078872508,-2.516522134
5.31,15.63985153,0.08085029793,-2.519645358
5.32,15.63946105,0.08091217398,-2.522762787
5.33,15.63908822,0.08097435309,-2.525874305
5.34,15.63873308,0.08103683511,-2.528979798
5.35,15.63839563,0.08109961989,-2.532079146
5.36,15.63807592,0.0811627073,-2.535172237
5.37,15.63777395,0.08122609727,-2.538258955
5.38,15.63748974,0.08128978973,-2.541339188
5.39,15.63722331,0.08135378465,-2.54441282
5.4,15.63697468,0.08141808203,-2.547479741
5.41,15.63674385,0.08148268193,-2.550539835
5.42,15.63653082,0.08154758438,-2.553592987
5.43,15.6363356,0.0816127894,-2.556639083
5.44,15.63615817,0.08167829703,-2.559678007
5.45,15.63599856,0.08174410731,-2.562709644
5.46,15.63585675,0.08181022035,-2.565733876
5.47,15.63573274,0.08187663624,-2.568750587
5.48,15.63562653,0.08194335506,-2.571759659
5.49,15.63553812,0.08201037689,-2.574760973
5.5,15.63546751,0.08207770184,-2.577754414
5.51,15.6354147,0.08214533003,-2.580739862
5.52,15.63537968,0.0822132616,-2.5837172
5.53,15.63536245,0.08228149672,-2.586686315
5.54,15.63536299,0.08235003553,-2.589647092
5.55,15.6353813,0.08241887817,-2.592599418
5.56,15.63541737,0.08248802483,-2.59554318
5.57,15.63547118,0.08255747574,-2.598478266
5.58,15.63554273,0.0826272311,-2.601404562
5.59,15.635632,0.0826972911,-2.604321955
5.6,15.63573896,0.08276765589,-2.607230333
5.61,15.6358636,0.08283832564,-2.61012958
5.62,15.6360059,0.08290930056,-2.613019583
5.63,15.63616582,0.08298058083,-2.615900227
5.64,15.63634333,0.08305216671,-2.618771398
5.65,15.6365384,0.08312405841,-2.621632983
5.66,15.63675099,0.08319625619,-2.624484869
5.67,15.63698107,0.08326876029,-2.627326941
5.68,15.63722858,0.08334157097,-2.630159084
5.69,15.63749348,0.0834146886,-2.632981181
5.7,15.63777571,0.08348811347,-2.635793117
5.71,15.63807522,0.0835618459,-2.638594774
5.72,15.63839194,0.08363588621,-2.641386037
5.73,15.63872581,0.08371023476,-2.64416679
5.74,15.63907675,0.08378489188,-2.646936916
5.75,15.63944468,0.08385985792,-2.649696301
5.76,15.63982952,0.08393513321,-2.652444828
5.77,15.64023118,0.08401071816,-2.655182382
5.78,15.64064957,0.08408661313,-2.657908849
5.79,15.6410846,0.08416281849,-2.660624114
5.8,15.64153618,0.08423933462,-2.663328066
5.81,15.64200422,0.08431616187,-2.666020591
5.82,15.64248862,0.08439330061,-2.668701574
5.83,15.64298928,0.08447075124,-2.671370898
5.84,15.64350609,0.08454851417,-2.674028445
5.85,15.64403895,0.08462658981,-2.676674095
5.86,15.64458773,0.08470497854,-2.679307729
5.87,15.64515232,0.08478368073,-2.681929225
5.88,15.64573262,0.08486269671,-2.684538465
5.89,15.64632852,0.08494202685,-2.687135328
5.9,15.64693989,0.08502167152,-2.689719694
5.91,15.64756662,0.08510163113,-2.692291443
5.92,15.64820859,0.08518190611,-2.694850454
5.93,15.64886571,0.08526249687,-2.697396609
5.94,15.64953787,0.08534340381,-2.699929787
5.95,15.65022497,0.08542462733,-2.702449872
5.96,15.65092691,0.08550616778,-2.704956747
5.97,15.6516436,0.08558802547,-2.707450297
5.98,15.65237494,0.08567020075,-2.709930411
5.99,15.65312082,0.085752694,-2.71239698
6,15.65388115,0.08583550566,-2.714849899
6.01,15.65465584,0.08591863615,-2.717289067
6.02,15.6554448,0.08600208578,-2.719714384
6.03,15.65624796,0.08608585476,-2.722125756
6.04,15.65706522,0.08616994303,-2.724523086
6.05,15.65789651,0.08625435038,-2.726906281
6.06,15.65874172,0.08633907632,-2.729275242
6.07,15.65960076,0.08642412018,-2.731629871
6.08,15.66047358,0.08650948131,-2.733970077
6.09,15.66136013,0.08659515909,-2.736295771
6.1,15.66226038,0.08668115289,-2.738606869
6.11,15.66317432,0.08676746189,-2.740903284
6.12,15.66410192,0.08685408518,-2.743184925
6.13,15.66504315,0.08694102184,-2.745451705
6.14,15.665998,0.08702827091,-2.747703533
6.15,15.66696644,0.08711583143,-2.749940316
6.16,15.66794843,0.08720370259,-2.752161962
6.17,15.66894393,0.08729188349,-2.754368375
6.18,15.66995292,0.08738037335,-2.756559461
6.19,15.67097539,0.0874691713,-2.758735129
6.2,15.67201133,0.08755827643,-2.76089529
6.21,15.67306074,0.08764768773,-2.763039853
6.22,15.67412364,0.08773740417,-2.765168734
6.23,15.67520004,0.08782742465,-2.767281845
6.24,15.67628995,0.08791774803,-2.769379098
6.25,15.6773934,0.08800837313,-2.771460402
6.26,15.67851039,0.08809929878,-2.77352567
6.27,15.67964093,0.08819052385,-2.775574817
6.28,15.68078503,0.08828204717,-2.77760776
6.29,15.68194272,0.08837386753,-2.779624419
6.3,15.68311402,0.08846598372,-2.781624718
6.31,15.684299,0.08855839452,-2.78360858
6.32,15.68549771,0.08865109875,-2.785575932
6.33,15.68671018,0.08874409529,-2.787526701
6.34,15.68793647,0.08883738297,-2.789460816
6.35,15.68917663,0.08893096057,-2.791378207
6.36,15.69043072,0.0890248268,-2.793278806
6.37,15.69169881,0.0891189804,-2.795162543
6.38,15.69298096,0.08921341998,-2.797029351
6.39,15.69427724,0.08930814412,-2.798879165
6.4,15.69558775,0.08940315128,-2.800711916
6.41,15.69691257,0.08949843988,-2.802527534
6.42,15.69825179,0.08959400838,-2.80432595
6.43,15.69960551,0.08968985517,-2.806107098
6.44,15.7009738,0.08978597861,-2.807870909
6.45,15.70235676,0.08988237715,-2.809617315
6.46,15.70375446,0.08997904924,-2.811346249
6.47,15.70516698,0.09007599328,-2.813057646
6.48,15.70659441,0.09017320753,-2.814751442
6.49,15.70803684,0.09027069016,-2.816427578
6.5,15.70949437,0.09036843921,-2.818085992
6.51,15.71096711,0.09046645269,-2.819726622
6.52,15.71245517,0.09056472856,-2.821349408
6.53,15.71395866,0.09066326467,-2.822954291
6.54,15.71547766,0.0907620588,-2.824541215
6.55,15.71701226,0.0908611087,-2.826110129
6.56,15.71856258,0.09096041198,-2.82766098
6.57,15.72012874,0.09105996618,-2.829193715
6.58,15.72171087,0.09115976876,-2.830708281
6.59,15.72330908,0.09125981726,-2.832204625
6.6,15.72492347,0.09136010934,-2.833682698
6.61,15.72655419,0.09146064254,-2.835142452
6.62,15.72820136,0.0915614143,-2.836583829
6.63,15.72986511,0.09166242216,-2.838006749
6.64,15.73154555,0.09176366373,-2.839411126
6.65,15.73324277,0.09186513655,-2.840796877
6.66,15.7349569,0.09196683818,-2.842163915
6.67,15.73668803,0.09206876618,-2.843512149
6.68,15.73843628,0.09217091789,-2.844841496
6.69,15.74020178,0.09227329042,-2.846151875
6.7,15.74198468,0.09237588072,-2.847443209
6.71,15.74378513,0.09247868573,-2.848715427
6.72,15.74560328,0.09258170231,-2.849968462
6.73,15.74743928,0.09268492732,-2.851202251
6.74,15.74929332,0.09278835763,-2.852416737
6.75,15.75116552,0.09289199016,-2.853611864
6.76,15.75305599,0.09299582216,-2.854787569
6.77,15.75496483,0.09309985094,-2.855943784
6.78,15.7568921,0.09320407379,-2.857080442
6.79,15.75883789,0.09330848791,-2.858197475
6.8,15.76080226,0.09341309046,-2.859294811
6.81,15.76278533,0.09351787873,-2.860372382
6.82,15.76478718,0.09362285024,-2.861430124
6.83,15.76680793,0.09372800251,-2.862467976
6.84,15.76884767,0.09383333323,-2.863485876
6.85,15.77090649,0.09393884045,-2.864483747
6.86,15.77298445,0.09404452225,-2.865461508
6.87,15.7750816,0.09415037679,-2.866419081
6.88,15.77719797,0.09425640224,-2.867356391
6.89,15.77933355,0.09436259669,-2.868273359
6.9,15.78148835,0.09446895813,-2.869169908
6.91,15.78366234,0.09457548461,-2.870045957
6.92,15.78585549,0.09468217441,-2.87090143
6.93,15.78806776,0.09478902599,-2.871736248
6.94,15.7902991,0.09489603785,-2.87255033
6.95,15.79254948,0.09500320887,-2.873343614
6.96,15.79481886,0.09511053796,-2.874116043
6.97,15.79710721,0.09521802379,-2.874867563
6.98,15.79941453,0.09532566494,-2.875598127
6.99,15.80174079,0.09543346015,-2.876307683
7,15.80408594,0.09554140833,-2.87699619
7.01,15.80644991,0.09564950866,-2.877663607
7.02,15.80883263,0.09575776036,-2.878309888
7.03,15.81123403,0.09586616255,-2.878934982
7.04,15.81365401,0.09597471429,-2.87953884
7.05,15.81609248,0.09608341448,-2.880121411
7.06,15.81854936,0.09619226211,-2.880682642
7.07,15.82102451,0.09630125615,-2.881222476
7.08,15.82351782,0.09641039522,-2.881740856
7.09,15.82602918,0.09651967771,-2.882237724
7.1,15.82855849,0.0966291019,-2.882713025
7.11,15.83110563,0.09673866582,-2.883166704
7.12,15.83367054,0.09684836722,-2.883598706
7.13,15.83625313,0.09695820381,-2.884008978
7.14,15.83885331,0.09706817345,-2.884397466
7.15,15.84147099,0.09717827402,-2.884764115
7.16,15.84410609,0.09728850338,-2.885108874
7.17,15.84675854,0.09739885936,-2.885431701
7.18,15.84942827,0.09750933977,-2.88573256
7.19,15.8521152,0.0976199425,-2.886011411
7.2,15.85481926,0.09773066541,-2.886268214
7.21,15.85754036,0.09784150643,-2.886502925
7.22,15.86027843,0.09795246363,-2.886715498
7.23,15.8630334,0.09806353516,-2.886905877
7.24,15.86580523,0.0981747191,-2.887074007
7.25,15.86859384,0.09828601353,-2.887219829
7.26,15.87139916,0.09839741656,-2.887343285
7.27,15.87422109,0.0985089263,-2.887444307
7.28,15.87705956,0.0986205408,-2.887522826
7.29,15.87991449,0.0987322581,-2.887578765
7.3,15.8827858,0.09884407618,-2.887612048
7.31,15.88567342,0.09895599291,-2.887622597
7.32,15.88857729,0.09906800625,-2.887610331
7.33,15.89149732,0.09918011431,-2.887575169
7.34,15.89443345,0.09929231517,-2.88751704
7.35,15.89738559,0.09940460667,-2.887435874
7.36,15.90035366,0.09951698636,-2.887331598
7.37,15.90333763,0.09962945158,-2.887204135
7.38,15.90633743,0.09974199966,-2.887053405
7.39,15.90935303,0.09985462783,-2.886879332
7.4,15.91238438,0.09996733322,-2.886681844
7.41,15.91543143,0.1000801128,-2.886460863
7.42,15.91849415,0.1001929633,-2.886216321
7.43,15.92157256,0.1003058815,-2.885948148
7.44,15.92466663,0.1004188642,-2.885656271
7.45,15.92777636,0.1005319081,-2.885340617
7.46,15.93090173,0.1006450104,-2.885001116
7.47,15.93404268,0.1007581684,-2.884637703
7.48,15.93719919,0.1008713796,-2.884250312
7.49,15.94037121,0.1009846415,-2.883838878
7.5,15.94355872,0.1010979515,-2.883403338
7.51,15.94676172,0.101211307,-2.882943633
7.52,15.94998021,0.1013247054,-2.8824597
7.53,15.95321418,0.1014381445,-2.881951482
7.54,15.95646363,0.1015516216,-2.881418919
7.55,15.95972859,0.1016651344,-2.880861947
7.56,15.9630091,0.1017786802,-2.880280501
7.57,15.96630522,0.1018922563,-2.879674518
7.58,15.96961705,0.1020058598,-2.879043934
7.59,15.97294467,0.1021194882,-2.878388687
7.6,15.97628818,0.1022331388,-2.877708716
7.61,15.97964769,0.102346809,-2.877003968
7.62,15.98302334,0.1024604962,-2.876274389
7.63,15.98641525,0.1025741975,-2.875519926
7.64,15.98982358,0.1026879099,-2.874740531
7.65,15.99324846,0.1028016308,-2.873936161
7.66,15.99668999,0.1029153577,-2.873106772
7.67,16.00014822,0.1030290883,-2.872252317
7.68,16.00362324,0.1031428202,-2.871372749
7.69,16.00711512,0.1032565512,-2.870468024
7.7,16.01062395,0.1033702789,-2.869538096
7.71,16.01414979,0.1034840012,-2.868582922
7.72,16.01769271,0.1035977157,-2.867602459
7.73,16.0212528,0.1037114203,-2.866596668
7.74,16.02483016,0.1038251131,-2.865565511
7.75,16.02842486,0.1039387927,-2.864508962
7.76,16.03203696,0.1040524578,-2.863426992
7.77,16.03566653,0.1041661072,-2.862319578
7.78,16.03931363,0.1042797401,-2.861186701
7.79,16.0429783,0.104393356,-2.860028349
7.8,16.04666057,0.1045069546,-2.85884452
7.81,16.05036045,0.104620536,-2.857635215
7.82,16.05407796,0.1047341001,-2.856400442
7.83,16.05781308,0.1048476473,-2.855140202
7.84,16.06156582,0.1049611777,-2.853854498
7.85,16.06533619,0.1050746918,-2.852543334
7.86,16.06912421,0.1051881898,-2.851206714
7.87,16.07292994,0.1053016723,-2.849844651
7.88,16.07675341,0.1054151398,-2.848457157
7.89,16.0805947,0.1055285927,-2.847044252
7.9,16.08445385,0.105642032,-2.845605954
7.91,16.08833093,0.105755458,-2.844142293
7.92,16.09222599,0.1058688716,-2.842653298
7.93,16.09613907,0.1059822736,-2.841139001
7.94,16.10007019,0.106095665,-2.839599426
7.95,16.10401938,0.1062090474,-2.838034605
7.96,16.10798664,0.1063224227,-2.836444582
7.97,16.11197197,0.1064357927,-2.834829399
7.98,16.11597535,0.1065491596,-2.8331891
7.99,16.11999674,0.1066625262,-2.831523742
8,16.12403608,0.1067758959,-2.829833392
8.01,16.12809329,0.1068892731,-2.828118123
8.02,16.13216831,0.1070026615,-2.826378011
8.03,16.13626103,0.1071160654,-2.824613137
8.04,16.14037137,0.1072294882,-2.822823586
8.05,16.14449926,0.1073429338,-2.821009436
8.06,16.14864462,0.1074564054,-2.819170771
8.07,16.15280742,0.1075699063,-2.817307674
8.08,16.1569876,0.1076834398,-2.815420235
8.09,16.16118512,0.1077970091,-2.81350854
8.1,16.16539994,0.1079106178,-2.811572681
8.11,16.169632,0.1080242693,-2.809612752
8.12,16.17388126,0.1081379669,-2.807628851
8.13,16.17814765,0.1082517139,-2.805621077
8.14,16.18243113,0.1083655135,-2.803589534
8.15,16.18673161,0.1084793689,-2.801534327
8.16,16.19104901,0.1085932831,-2.799455561
8.17,16.19538326,0.1087072593,-2.797353351
8.18,16.19973428,0.1088213005,-2.795227814
8.19,16.204102,0.1089354095,-2.79307907
8.2,16.20848633,0.1090495891,-2.790907244
8.21,16.21288718,0.109163842,-2.788712464
8.22,16.21730449,0.1092781708,-2.786494865
8.23,16.22173824,0.1093925783,-2.784254588
8.24,16.22618838,0.1095070673,-2.781991777
8.25,16.23065488,0.1096216412,-2.779706575
8.26,16.23513769,0.1097363032,-2.77739913
8.27,16.23963677,0.1098510567,-2.775069592
8.28,16.24415207,0.1099659048,-2.772718111
8.29,16.24868358,0.1100808504,-2.770344849
8.3,16.25323129,0.1101958969,-2.767949974
8.31,16.25779515,0.1103110474,-2.765533663
8.32,16.26237512,0.1104263052,-2.763096085
8.33,16.26697114,0.1105416733,-2.760637419
8.34,16.27158317,0.1106571549,-2.758157845
8.35,16.2762112,0.1107727528,-2.755657535
8.36,16.28085521,0.1108884702,-2.753136653
8.37,16.28551519,0.1110043105,-2.750595369
8.38,16.29019112,0.1111202774,-2.748033847
8.39,16.29488299,0.1112363743,-2.745452253
8.4,16.29959082,0.1113526039,-2.742850747
8.41,16.30431468,0.1114689689,-2.740229492
8.42,16.30905466,0.1115854713,-2.737588651
8.43,16.31381089,0.111702113,-2.734928391
8.44,16.31858352,0.1118188951,-2.732248873
8.45,16.32337268,0.1119358189,-2.729550255
8.46,16.3281785,0.1120528855,-2.726832691
8.47,16.33300108,0.1121700958,-2.724096343
8.48,16.33784054,0.1122874511,-2.721341371
8.49,16.34269702,0.1124049528,-2.718567936
8.5,16.34757065,0.1125226021,-2.715776206
8.51,16.35246155,0.1126404003,-2.712966353
8.52,16.35736977,0.1127583486,-2.710138549
8.53,16.36229535,0.1128764484,-2.707292954
8.54,16.36723828,0.1129947009,-2.704429721
8.55,16.37219857,0.1131131073,-2.701548988
8.56,16.37717621,0.1132316691,-2.698650879
8.57,16.38217119,0.1133503872,-2.695735515
8.58,16.38718356,0.1134692623,-2.69280302
8.59,16.39221338,0.1135882949,-2.689853521
8.6,16.39726073,0.1137074844,-2.68688715
8.61,16.40232567,0.1138268304,-2.683904041
8.62,16.40740825,0.1139463316,-2.680904331
8.63,16.41250847,0.114065987,-2.677888161
8.64,16.41762634,0.1141857956,-2.674855663
8.65,16.42276186,0.1143057561,-2.671806962
8.66,16.427915,0.1144258673,-2.668742184
8.67,16.43308576,0.1145461284,-2.665661466
8.68,16.43827415,0.1146665381,-2.662564947
8.69,16.44348012,0.1147870956,-2.659452758
8.7,16.44870362,0.1149077996,-2.656325029
8.71,16.45394456,0.1150286494,-2.653181882
8.72,16.45920284,0.1151496443,-2.650023446
8.73,16.46447836,0.1152707838,-2.64684985
8.74,16.46977101,0.1153920679,-2.643661224
8.75,16.47508068,0.115513496,-2.640457698
8.76,16.48040727,0.1156350674,-2.637239406
8.77,16.48575062,0.1157567819,-2.634006466
8.78,16.49111053,0.1158786392,-2.63075898
8.79,16.49648682,0.1160006384,-2.627497052
8.8,16.50187931,0.1161227786,-2.62422078
8.81,16.50728779,0.1162450587,-2.620930251
8.82,16.51271204,0.1163674774,-2.617625556
8.83,16.51815186,0.1164900329,-2.614306785
8.84,16.52360704,0.1166127238,-2.610974036
8.85,16.52907734,0.1167355486,-2.607627405
8.86,16.53456255,0.1168585061,-2.604266989
8.87,16.54006243,0.1169815953,-2.600892883
8.88,16.54557672,0.1171048153,-2.597505174
8.89,16.55110521,0.117228166,-2.594103957
8.9,16.55664766,0.1173516477,-2.590689321
8.91,16.56220384,0.117475261,-2.587261341
8.92,16.56777347,0.1175990067,-2.58382009
8.93,16.57335632,0.1177228859,-2.58036565
8.94,16.57895213,0.1178468997,-2.57689811
8.95,16.58456066,0.11797105,-2.573417586
8.96,16.59018164,0.118095339,-2.56992422
8.97,16.59581476,0.1182197702,-2.566418167
8.98,16.60145972,0.1183443471,-2.562899591
8.99,16.60711624,0.1184690737,-2.559368659
9,16.612784,0.1185939543,-2.555825544
9.01,16.61846268,0.118718993,-2.552270421
9.02,16.62415193,0.1188441935,-2.548703463
9.03,16.62985143,0.1189695592,-2.545124846
9.04,16.63556084,0.1190950935,-2.541534748
9.05,16.64127986,0.1192207995,-2.537933346
9.06,16.64700818,0.1193466797,-2.534320822
9.07,16.65274553,0.1194727368,-2.530697358
9.08,16.65849156,0.1195989738,-2.527063125
9.09,16.66424593,0.1197253942,-2.523418287
9.1,16.67000829,0.1198520016,-2.519763015
9.11,16.6757783,0.1199787988,-2.516097486
9.12,16.68155567,0.1201057885,-2.51242188
9.13,16.68734014,0.1202329728,-2.508736388
9.14,16.69313144,0.1203603532,-2.505041194
9.15,16.6989293,0.1204879317,-2.501336474
9.16,16.70473342,0.1206157108,-2.497622373
9.17,16.71054348,0.1207436929,-2.49389903
9.18,16.71635916,0.1208718806,-2.490166582
9.19,16.72218009,0.121000277,-2.486425158
9.2,16.72800593,0.1211288854,-2.482674885
9.21,16.73383638,0.1212577087,-2.478915886
9.22,16.73967112,0.1213867497,-2.475148289
9.23,16.74550988,0.121516011,-2.471372234
9.24,16.75135242,0.1216454952,-2.467587862
9.25,16.75719846,0.1217752047,-2.463795311
9.26,16.76304778,0.1219051418,-2.459994722
9.27,16.76890015,0.1220353084,-2.456186243
9.28,16.77475531,0.1221657068,-2.452370017
9.29,16.78061305,0.122296339,-2.448546191
9.3,16.78647318,0.1224272064,-2.444714917
9.31,16.7923355,0.1225583098,-2.440876343
9.32,16.79819982,0.1226896499,-2.437030611
9.33,16.80406595,0.1228212269,-2.433177868
9.34,16.80993369,0.1229530411,-2.429318266
9.35,16.81580288,0.1230850925,-2.425451951
9.36,16.82167336,0.1232173809,-2.421579069
9.37,16.82754498,0.1233499056,-2.417699764
9.38,16.83341762,0.1234826656,-2.41381418
9.39,16.83929121,0.1236156601,-2.409922466
9.4,16.84516567,0.1237488885,-2.40602478
9.41,16.85104091,0.1238823503,-2.402121273
9.42,16.85691682,0.1240160449,-2.398212086
9.43,16.86279332,0.1241499714,-2.394297359
9.44,16.86867031,0.1242841292,-2.390377221
9.45,16.87454766,0.1244185164,-2.38645181
9.46,16.88042518,0.1245531309,-2.382521262
9.47,16.88630265,0.12468797,-2.378585711
9.48,16.89217991,0.1248230301,-2.374645284
9.49,16.89805681,0.1249583076,-2.370700117
9.5,16.90393322,0.1250937988,-2.366750341
9.51,16.90980904,0.1252294993,-2.362796081
9.52,16.91568416,0.1253654046,-2.358837455
9.53,16.92155847,0.1255015092,-2.354874597
9.54,16.9274319,0.125637807,-2.350907647
9.55,16.93330442,0.1257742917,-2.346936754
9.56,16.93917601,0.1259109571,-2.342962089
9.57,16.94504664,0.1260477973,-2.338983827
9.58,16.95091626,0.1261848064,-2.335002131
9.59,16.95678485,0.126321978,-2.331017157
9.6,16.96265239,0.1264593057,-2.327029067
9.61,16.96851888,0.126596783,-2.323038027
9.62,16.97438428,0.1267344031,-2.319044207
9.63,16.98024851,0.126872159,-2.315047783
9.64,16.98611153,0.1270100435,-2.311048945
9.65,16.9919733,0.1271480489,-2.307047911
9.66,16.99783377,0.1272861674,-2.3030449
9.67,17.00369292,0.1274243913,-2.299040116
9.68,17.00955076,0.1275627122,-2.295033771
9.69,17.0154073,0.1277011205,-2.291026079
9.7,17.02126254,0.1278396071,-2.287017267
9.71,17.02711648,0.1279781625,-2.283007566
9.72,17.03296919,0.1281167761,-2.278997206
9.73,17.03882079,0.1282554367,-2.274986422
9.74,17.04467134,0.1283941325,-2.270975435
9.75,17.05052093,0.1285328519,-2.266964477
9.76,17.05636964,0.1286715832,-2.262953784
9.77,17.06221758,0.1288103155,-2.258943566
9.78,17.06806487,0.1289490379,-2.254934021
9.79,17.07391161,0.1290877392,-2.250925363
9.8,17.07975793,0.1292264083,-2.246917817
9.81,17.08560398,0.1293650338,-2.242911621
9.82,17.09144992,0.1295036048,-2.238907004
9.83,17.09729592,0.1296421107,-2.234904202
9.84,17.10314217,0.1297805399,-2.230903449
9.85,17.10898883,0.1299188818,-2.226904961
9.86,17.11483609,0.1300571253,-2.222908953
9.87,17.12068414,0.1301952593,-2.218915641
9.88,17.12653317,0.1303332717,-2.214925246
9.89,17.13238341,0.1304711502,-2.210937991
9.9,17.13823504,0.1306088819,-2.206954113
9.91,17.14408821,0.1307464541,-2.202973857
9.92,17.14994299,0.1308838538,-2.198997468
9.93,17.15579947,0.1310210689,-2.195025191
9.94,17.16165771,0.131158087,-2.191057271
9.95,17.16751778,0.1312948959,-2.187093955
9.96,17.17337971,0.1314314832,-2.183135498
9.97,17.17924351,0.1315678377,-2.179182144
9.98,17.18510913,0.1317039489,-2.175234089
9.99,17.19097646,0.1318398075,-2.171291509
10,17.19684537,0.1319754062,-2.167354557
10.01,17.20271481,0.1321107116,-2.163424931
10.02,17.20858371,0.1322456914,-2.159504307
10.03,17.21446141,0.1323806073,-2.155577452
10.04,17.22033838,0.1325151827,-2.151659932
10.05,17.22621458,0.1326494119,-2.147751877
10.06,17.23208995,0.1327832889,-2.143853417
10.07,17.23796435,0.1329168081,-2.139964679
10.08,17.24383763,0.1330499641,-2.136085782
10.09,17.2497189,0.133183002,-2.132201824
10.1,17.25559871,0.1333156598,-2.128328069
10.11,17.26147688,0.1334479311,-2.12446464
10.12,17.26735321,0.1335798097,-2.120611662
10.13,17.27323658,0.1337115296,-2.116754446
10.14,17.27911759,0.1338428396,-2.112908045
10.15,17.28499602,0.1339737333,-2.109072593
10.16,17.29087168,0.1341042038,-2.105248232
10.17,17.29674442,0.1342342436,-2.101435107
10.18,17.30261407,0.1343638449,-2.097633361
10.19,17.30848944,0.1344932221,-2.093828681
10.2,17.31436141,0.1346221386,-2.090035771
10.21,17.32022987,0.1347505855,-2.08625476
10.22,17.32609474,0.1348785537,-2.082485776
10.23,17.33196468,0.1350062435,-2.078714756
10.24,17.33783072,0.13513343,-2.074956152
10.25,17.34368406,0.135259902,-2.071224139
10.26,17.34955068,0.1353862606,-2.067476618
10.27,17.35540439,0.1355118887,-2.063755906
10.28,17.36125376,0.1356369819,-2.060048072
10.29,17.36710719,0.1357617226,-2.056339452
10.3,17.37295593,0.1358859073,-2.052644119
10.31,17.37879985,0.1360095283,-2.048962201
10.32,17.38463881,0.1361325781,-2.045293827
10.33,17.39048109,0.1362552238,-2.041625612
10.34,17.39631815,0.1363772741,-2.037971351
10.35,17.40214161,0.136498552,-2.034344562
10.36,17.40797622,0.1366195518,-2.030705258
10.37,17.41379703,0.1367397611,-2.027093701
10.38,17.41961223,0.1368593393,-2.023496637
10.39,17.4254298,0.1369784301,-2.019901111
10.4,17.43124147,0.1370968646,-2.016320463
10.41,17.43704715,0.1372146341,-2.012754812
10.42,17.44284675,0.1373317297,-2.009204299
10.43,17.44864808,0.1374482788,-2.005656314
10.44,17.45444309,0.1375641272,-2.002123957
10.45,17.46022394,0.1376791367,-1.998620029
10.46,17.46601406,0.1377936816,-1.995106899
10.47,17.47179005,0.1379073681,-1.991622598
10.48,17.4775597,0.1380203165,-1.988154611
10.49,17.48333053,0.138132632,-1.984690666
10.5,17.48909477,0.138244189,-1.98124322
10.51,17.4948523,0.1383549825,-1.977812298
10.52,17.50060306,0.138465008,-1.97439793
10.53,17.50635437,0.1385743552,-1.970988197
10.54,17.51209869,0.1386829172,-1.967595258
10.55,17.51783598,0.1387906896,-1.964219154
10.56,17.52356621,0.1388976686,-1.960859927
10.57,17.52928936,0.1390038514,-1.957517618
10.58,17.53500536,0.1391092357,-1.954192276
10.59,17.54072126,0.1392138865,-1.950872516
10.6,17.54642982,0.1393177273,-1.947570027
10.61,17.55213101,0.1394207574,-1.944284883
10.62,17.55782478,0.139522976,-1.941017177
10.63,17.56351791,0.139624431,-1.937755892
10.64,17.56920336,0.1397250643,-1.934512377
10.65,17.57488108,0.1398248756,-1.931286704
10.66,17.58055101,0.1399238639,-1.928078927
10.67,17.58621307,0.1400220295,-1.924889073
10.68,17.59186719,0.1401193724,-1.921717158
10.69,17.59751983,0.1402159143,-1.918552622
10.7,17.60316425,0.1403116282,-1.9154062
10.71,17.60880035,0.140406518,-1.912277874
10.72,17.61442803,0.1405005876,-1.909167615
10.73,17.6200535,0.1405938429,-1.906065177
10.74,17.62567032,0.1406862752,-1.902980924
10.75,17.63127232,0.1407778944,-1.89992487
10.76,17.63687804,0.1408686853,-1.896866855
10.77,17.64246895,0.1409586709,-1.893836978
10.78,17.64805124,0.141047849,-1.890825172
10.79,17.65363089,0.1411361997,-1.887821724
10.8,17.65920183,0.1412237428,-1.884836492
10.81,17.66476408,0.1413104831,-1.881869472
10.82,17.6703176,0.1413964262,-1.878920675
10.83,17.67586811,0.1414815374,-1.875980787
10.84,17.68140977,0.1415658574,-1.873059359
10.85,17.686937,0.1416494455,-1.870165594
10.86,17.69246666,0.1417321613,-1.867272061
10.87,17.69798202,0.1418141651,-1.864406279
10.88,17.70348883,0.1418954178,-1.861559134
10.89,17.70899258,0.1419758642,-1.858721876
10.9,17.71448794,0.1420555748,-1.855903548
10.91,17.71997505,0.1421345614,-1.853104216
10.92,17.72545409,0.1422128359,-1.850323949
10.93,17.7309304,0.1422903286,-1.847554389
10.94,17.73639891,0.1423671275,-1.844804236
10.95,17.7418547,0.1424433367,-1.84208181
10.96,17.74731302,0.1425187034,-1.839362469
10.97,17.75275884,0.1425935132,-1.836671023
10.98,17.75819725,0.1426676965,-1.833999077
10.99,17.76363313,0.1427411706,-1.83133855
11,17.76906162,0.1428140533,-1.828697312
11.01,17.7744828,0.1428863659,-1.826075195
11.02,17.77989679,0.1429581288,-1.823472032
11.03,17.78530835,0.143029246,-1.820880144
11.04,17.79071292,0.1430998474,-1.818307057
11.05,17.79611065,0.1431699528,-1.815752595
11.06,17.80150171,0.1432395818,-1.813216591
11.07,17.80688626,0.1433087542,-1.810698884
11.08,17.81226446,0.1433774901,-1.808199299
11.09,17.81764083,0.1434456773,-1.805710644
11.1,17.82301112,0.1435134626,-1.80323989
11.11,17.8283755,0.1435808652,-1.800786846
11.12,17.83373417,0.1436479036,-1.798351307
11.13,17.83909151,0.143714455,-1.795926393
11.14,17.84444354,0.1437806739,-1.793518716
11.15,17.84979053,0.1438465771,-1.791128062
11.16,17.85513277,0.1439121816,-1.788754211
11.17,17.86047052,0.1439775032,-1.786396945
11.18,17.86580406,0.1440425578,-1.78405606
11.19,17.87113764,0.1441072085,-1.781725147
11.2,17.87646758,0.1441716191,-1.779410339
11.21,17.88179422,0.1442358042,-1.777111427
11.22,17.88711791,0.1442997771,-1.774828211
11.23,17.89244282,0.1443633924,-1.772554587
11.24,17.8977654,0.1444268191,-1.770296396
11.25,17.90308224,0.1444902308,-1.7680592
11.26,17.908405,0.1445531568,-1.76582551
11.27,17.91372275,0.1446160923,-1.763612394
11.28,17.91903964,0.1446788868,-1.761413875
11.29,17.92435971,0.1447413834,-1.759224255
11.3,17.92967961,0.1448037545,-1.757048937
11.31,17.93499964,0.1448660085,-1.754887716
11.32,17.94032004,0.1449281532,-1.752740417
11.33,17.94564461,0.144990026,-1.750601634
11.34,17.95096987,0.145051801,-1.748476542
11.35,17.95629238,0.1451136553,-1.74637005
11.36,17.96162305,0.1451750772,-1.7442667
11.37,17.96695126,0.1452365883,-1.74218164
11.38,17.97228079,0.1452980205,-1.740109656
11.39,17.97761535,0.1453592012,-1.73804579
11.4,17.98295159,0.1454203067,-1.735994923
11.41,17.9882897,0.145481338,-1.733957032
11.42,17.99362985,0.145542296,-1.731932137
11.43,17.99897577,0.1456030018,-1.729915618
11.44,18.00432414,0.1456636328,-1.727912185
11.45,18.00967169,0.1457243692,-1.725926302
11.46,18.01502911,0.1457846651,-1.723944482
11.47,18.02038614,0.1458450622,-1.721980168
11.48,18.02574652,0.1459053755,-1.720028867
11.49,18.03111404,0.1459654155,-1.718086336
11.5,18.03648546,0.1460253603,-1.716156953
11.51,18.0418611,0.1460852021,-1.714240722
11.52,18.04724133,0.1461449311,-1.712337634
11.53,18.05262998,0.1462043469,-1.710443661
11.54,18.05802385,0.1462636254,-1.708562896
11.55,18.06342324,0.1463227559,-1.706695321
11.56,18.06882851,0.146381726,-1.704840901
11.57,18.07424006,0.1464405217,-1.702999581
11.58,18.07965819,0.1464991301,-1.701171306
11.59,18.08508668,0.1465573429,-1.69935239
11.6,18.09052219,0.1466153429,-1.697546582
11.61,18.09596494,0.1466731171,-1.695753889
11.62,18.10141509,0.1467306524,-1.693974307
11.63,18.10687647,0.1467877307,-1.692204458
11.64,18.11234567,0.146844538,-1.690447895
11.65,18.11781923,0.1469012665,-1.688707972
11.66,18.12330832,0.1469572787,-1.686974962
11.67,18.1288022,0.1470131812,-1.685258812
11.68,18.13430471,0.1470687518,-1.68355637
11.69,18.13981982,0.1471237602,-1.681864743
11.7,18.14534409,0.1471784037,-1.680187233
11.71,18.15087776,0.1472326681,-1.678523994
11.72,18.15642104,0.1472865411,-1.676875181
11.73,18.16197794,0.1473397869,-1.675238197
11.74,18.16754486,0.1473926134,-1.673616094
11.75,18.17311803,0.1474452383,-1.672011655
11.76,18.17870915,0.1474969645,-1.67041714
11.77,18.1843066,0.1475484686,-1.668840576
11.78,18.18991423,0.1475995122,-1.667279433
11.79,18.195536,0.147649848,-1.665731548
11.8,18.20116798,0.1476997009,-1.664199655
11.81,18.20681017,0.1477490627,-1.662683984
11.82,18.21246253,0.1477979253,-1.661184691
11.83,18.21812913,0.1478460306,-1.659699837
11.84,18.22380601,0.1478936129,-1.658231769
11.85,18.2294891,0.1479409185,-1.65678253
11.86,18.23519084,0.1479871748,-1.655346496
11.87,18.24089884,0.1480331405,-1.653929524
11.88,18.24661719,0.1480785549,-1.652529773
11.89,18.25235007,0.1481231498,-1.651145396
11.9,18.25809322,0.1481671843,-1.649777831
11.91,18.26384666,0.1482106561,-1.648426793
11.92,18.26961049,0.1482535637,-1.647092013
11.93,18.27538917,0.1482956289,-1.645771919
11.94,18.28117852,0.1483371263,-1.644467744
11.95,18.28697428,0.1483783424,-1.643180443
11.96,18.2927898,0.1484184283,-1.641906263
11.97,18.29861201,0.1484582371,-1.640648584
11.98,18.30444546,0.1484974871,-1.639406115
11.99,18.31029484,0.1485358858,-1.638177838
12,18.31615574,0.1485737253,-1.636964688
12.01,18.32202815,0.1486110095,-1.63576655
12.02,18.32791203,0.1486477413,-1.634583316
12.03,18.33381188,0.1486836179,-1.63341424
12.04,18.33972295,0.1487189427,-1.632260031
12.05,18.34564505,0.1487537173,-1.631120601
12.06,18.35157795,0.1487879426,-1.62999587
12.07,18.35752144,0.1488216185,-1.628885792
12.08,18.36347532,0.148854745,-1.627790339
12.09,18.36944425,0.1488869979,-1.626709217
12.1,18.37542335,0.1489186929,-1.625642802
12.11,18.38141249,0.1489498282,-1.624591085
12.12,18.38741155,0.1489804008,-1.62355407
12.13,18.39342532,0.1490100736,-1.622531719
12.14,18.39944881,0.1490391726,-1.621524107
12.15,18.40548186,0.1490676961,-1.620531193
12.16,18.41152432,0.1490956417,-1.619552944
12.17,18.417576,0.149123006,-1.618589316
12.18,18.4236367,0.1491497852,-1.617640253
12.19,18.42971127,0.1491756228,-1.616706066
12.2,18.43579451,0.1492008576,-1.615786447
12.21,18.44188621,0.1492254832,-1.614881349
12.22,18.44798614,0.1492494935,-1.613990726
12.23,18.45409913,0.1492725192,-1.613115165
12.24,18.46021981,0.1492949098,-1.612254115
12.25,18.46634272,0.1493170262,-1.611406756
12.26,18.47248299,0.1493377521,-1.610575272
12.27,18.47862491,0.1493581872,-1.609757349
12.28,18.48477331,0.149377954,-1.608953679
12.29,18.49093311,0.1493966626,-1.60816518
12.3,18.49709883,0.1494146811,-1.607390861
12.31,18.50327015,0.1494320026,-1.606630617
12.32,18.50944676,0.1494486205,-1.605884379
12.33,18.51563353,0.149464133,-1.605153337
12.34,18.52182492,0.1494789203,-1.604436324
12.35,18.52801534,0.1494933771,-1.603731928
12.36,18.53422004,0.1495062916,-1.603044182
12.37,18.54042302,0.149518863,-1.602368977
12.38,18.54662911,0.1495306823,-1.601707625
12.39,18.55284312,0.1495413267,-1.601061679
12.4,18.55905946,0.1495511983,-1.600429611
12.41,18.56527774,0.1495602905,-1.599811387
12.42,18.57149766,0.1495685966,-1.599207018
12.43,18.57772405,0.1495756812,-1.598618359
12.44,18.58395147,0.1495819643,-1.598043684
12.45,18.59017454,0.1495878807,-1.597481037
12.46,18.59640849,0.1495921173,-1.596936268
12.47,18.60263779,0.1495959832,-1.596403531
12.48,18.6088675,0.1495990394,-1.595884783
12.49,18.61510259,0.1496008328,-1.595382233
12.5,18.62133784,0.1496018079,-1.59489382
12.51,18.62757312,0.1496019653,-1.594419566
12.52,18.63380833,0.1496013063,-1.593959507
12.53,18.64004842,0.1495993676,-1.593516141
12.54,18.64628823,0.1495966137,-1.59308719
12.55,18.65252758,0.1495930518,-1.592672641
12.56,18.65876628,0.1495886884,-1.592272457
12.57,18.66500418,0.1495835292,-1.591886588
12.58,18.67124106,0.1495775799,-1.591514992
12.59,18.6774816,0.1495703595,-1.591160412
12.6,18.68372063,0.1495623544,-1.590820154
12.61,18.6899579,0.1495535716,-1.590494245
12.62,18.69619316,0.1495440185,-1.590182717
12.63,18.70243097,0.1495332027,-1.589888658
12.64,18.7086662,0.1495216283,-1.589609211
12.65,18.71489862,0.1495093069,-1.589344474
12.66,18.72112798,0.1494962485,-1.589094557
12.67,18.72735416,0.149482461,-1.588859581
12.68,18.73357703,0.149467951,-1.588639708
12.69,18.73980108,0.1494522044,-1.588438529
12.7,18.74602148,0.1494357415,-1.588252971
12.71,18.75223813,0.1494185678,-1.588083276
12.72,18.7584509,0.1494006879,-1.58792971
12.73,18.7646642,0.1493815735,-1.587796207
12.74,18.77087339,0.1493617556,-1.587679467
12.75,18.77707398,0.1493417792,-1.587575874
12.76,18.78327936,0.1493200373,-1.587497098
12.77,18.78947622,0.1492981554,-1.587431931
12.78,18.79566907,0.1492756054,-1.587384452
12.79,18.80186225,0.1492518483,-1.58735908
12.8,18.80805144,0.1492274395,-1.587352156
12.81,18.81423665,0.1492023908,-1.587363987
12.82,18.82041794,0.1491767141,-1.587394892
12.83,18.82659953,0.1491498617,-1.587449569
12.84,18.83277713,0.1491224063,-1.58752404
12.85,18.83895069,0.1490943659,-1.587618575
12.86,18.84512018,0.1490657584,-1.587733432
12.87,18.8512855,0.1490366026,-1.587868801
12.88,18.85744657,0.1490069151,-1.588024848
12.89,18.86360728,0.1489761356,-1.588206677
12.9,18.86976346,0.1489448517,-1.588409753
12.91,18.87591511,0.1489130805,-1.588634157
12.92,18.8820622,0.1488808434,-1.588879416
12.93,18.88820854,0.14884758,-1.589150173
12.94,18.89435011,0.1488138953,-1.589440795
12.95,18.90048295,0.1487804026,-1.589745383
12.96,18.90661832,0.1487453654,-1.590079719
12.97,18.91274465,0.1487105705,-1.590427132
12.98,18.91886563,0.148675454,-1.590792631
12.99,18.92498477,0.1486394466,-1.591181563
13,18.93109816,0.1486031637,-1.591588063
13.01,18.93720561,0.1485666317,-1.592011866
13.02,18.9433069,0.1485298764,-1.592452729
13.03,18.94940532,0.1484923256,-1.592916383
13.04,18.95549706,0.1484546007,-1.593396766
13.05,18.96158199,0.1484167271,-1.593893681
13.06,18.96766003,0.1483787302,-1.594406932
13.07,18.97373112,0.1483406352,-1.594936321
13.08,18.97979522,0.1483024679,-1.595481638
13.09,18.98585558,0.1482636527,-1.596049058
13.1,18.99190898,0.1482248161,-1.596632163
13.11,18.99795552,0.1481859846,-1.597230719
13.12,19.00399531,0.1481471844,-1.597844481
13.13,19.01003154,0.1481078409,-1.598479848
13.14,19.01606113,0.1480685817,-1.599130126
13.15,19.02208417,0.1480294305,-1.599795096
13.16,19.0281007,0.1479904101,-1.600474556
13.17,19.0341107,0.1479515408,-1.601168339
13.18,19.04011417,0.1479128401,-1.60187631
13.19,19.046114,0.1478737241,-1.602605331
13.2,19.05210731,0.1478348101,-1.603348499
13.21,19.0580942,0.1477961144,-1.604105777
13.22,19.06407471,0.147757653,-1.604877132
13.23,19.0700516,0.1477188452,-1.605669662
13.24,19.07602217,0.1476803039,-1.606476179
13.25,19.08198389,0.1476426368,-1.607289253
13.26,19.08794477,0.1476040702,-1.608130671
13.27,19.09389712,0.1475664013,-1.608978434
13.28,19.09984374,0.1475290429,-1.609839727
13.29,19.10578729,0.1474914105,-1.610721921
13.3,19.11172542,0.147454104,-1.611617432
13.31,19.11765829,0.1474171285,-1.612526089
13.32,19.12358607,0.1473804869,-1.613447708
13.33,19.12951124,0.1473435937,-1.614389815
13.34,19.13543156,0.1473070396,-1.615344553
13.35,19.14134494,0.1472714105,-1.616303833
13.36,19.14725832,0.1472349509,-1.61729085
13.37,19.15316495,0.1471994177,-1.618281825
13.38,19.15906715,0.1471642261,-1.61928427
13.39,19.16496716,0.1471287951,-1.620305918
13.4,19.17086286,0.147093708,-1.621338611
13.41,19.17675443,0.1470589612,-1.622382114
13.42,19.18264205,0.1470245491,-1.623436226
13.43,19.18852792,0.1469898885,-1.624508983
13.44,19.1944101,0.1469555515,-1.625592173
13.45,19.20028673,0.1469221049,-1.626677413
13.46,19.20616384,0.1468878132,-1.627789404
13.47,19.21203568,0.1468543897,-1.628903322
13.48,19.21790439,0.1468212459,-1.630027443
13.49,19.22377207,0.1467877966,-1.631170249
13.5,19.22963713,0.1467546004,-1.632323499
13.51,19.23549995,0.1467216439,-1.633487328
13.52,19.24136084,0.1466889142,-1.634661892
13.53,19.24722188,0.1466558314,-1.635855952
13.54,19.25308151,0.1466229588,-1.637061076
13.55,19.25893992,0.1465902912,-1.638277261
13.56,19.26479726,0.1465578249,-1.639504451
13.57,19.27065371,0.146525556,-1.640742619
13.58,19.27650958,0.1464934814,-1.641991824
13.59,19.28236693,0.1464610289,-1.643260997
13.6,19.28822452,0.1464287587,-1.644541408
13.61,19.29408268,0.1463966621,-1.645832998
13.62,19.29994172,0.1463647307,-1.647135636
13.63,19.30580342,0.1463323921,-1.648458178
13.64,19.31166616,0.146300204,-1.649791527
13.65,19.31753001,0.1462681594,-1.651135589
13.66,19.32339499,0.14623625,-1.652490271
13.67,19.32926119,0.1462044665,-1.653855488
13.68,19.33512869,0.1461727989,-1.655231166
13.69,19.34099916,0.1461406734,-1.656626566
13.7,19.34687105,0.1461086426,-1.658032455
13.71,19.35274437,0.1460766945,-1.659448743
13.72,19.35861915,0.146044817,-1.66087532
13.73,19.36449697,0.1460124329,-1.662321536
13.74,19.3703764,0.1459800923,-1.663777845
13.75,19.37625604,0.1459483446,-1.665234567
13.76,19.38214053,0.1459154837,-1.666720116
13.77,19.38802548,0.1458831884,-1.668205711
13.78,19.39391259,0.1458508813,-1.669700729
13.79,19.39980351,0.1458179832,-1.671214707
13.8,19.40569694,0.1457850449,-1.672737906
13.81,19.41159302,0.145752055,-1.674270065
13.82,19.41749173,0.1457190089,-1.675810415
13.83,19.4233944,0.1456853385,-1.677367848
13.84,19.42929941,0.1456516076,-1.678931752
13.85,19.43520513,0.1456183803,-1.680491433
13.86,19.44111576,0.1455839499,-1.682075607
13.87,19.44702673,0.1455500169,-1.683653935
13.88,19.45293924,0.1455160134,-1.685235474
13.89,19.45885446,0.1454813712,-1.686829425
13.9,19.46477069,0.1454466623,-1.688425267
13.91,19.47068762,0.1454118919,-1.690022405
13.92,19.47660497,0.1453770667,-1.691620238
13.93,19.48252378,0.1453416223,-1.693228083
13.94,19.4884424,0.1453061344,-1.694835345
13.95,19.4943592,0.1452711829,-1.696431266
13.96,19.50027783,0.1452350632,-1.698044813
13.97,19.50619398,0.1451995046,-1.699645519
13.98,19.5121086,0.1451639523,-1.701242674
13.99,19.51802262,0.1451278516,-1.702845626
14,19.52393444,0.1450917926,-1.70444399
14.01,19.52984375,0.1450557921,-1.706037344
14.02,19.53575028,0.1450198647,-1.707625269
14.03,19.54165491,0.144983453,-1.709217179
14.04,19.54755611,0.1449471444,-1.71080281
14.05,19.55345355,0.1449109544,-1.712381774
14.06,19.55934689,0.1448748986,-1.713953683
14.07,19.56523573,0.1448389948,-1.715518079
14.08,19.57111964,0.1448032612,-1.717074501
14.09,19.57699914,0.1447671456,-1.71863221
14.1,19.58287277,0.1447312358,-1.720181061
14.11,19.58874012,0.1446955468,-1.721720628
14.12,19.59460085,0.1446600936,-1.723250508
14.13,19.60045549,0.1446243239,-1.724779883
14.14,19.60630293,0.1445888233,-1.726298787
14.15,19.61214222,0.1445541733,-1.727797397
14.16,19.61797545,0.1445186911,-1.72930382
14.17,19.62380029,0.1444840895,-1.730789272
14.18,19.62961737,0.1444498166,-1.732262919
14.19,19.63542723,0.1444153237,-1.733733788
14.2,19.6412291,0.1443811901,-1.735192204
14.21,19.64702294,0.1443474317,-1.736637946
14.22,19.65280872,0.1443140638,-1.738070758
14.23,19.65858679,0.1442805437,-1.739499496
14.24,19.66435661,0.1442474467,-1.740914608
14.25,19.67011783,0.1442153421,-1.742306734
14.26,19.67587143,0.1441825793,-1.743702749
14.27,19.6816164,0.1441508361,-1.745075018
14.28,19.68735294,0.1441195693,-1.74643213
14.29,19.69308117,0.1440882418,-1.747782516
14.3,19.69880086,0.1440574179,-1.749116792
14.31,19.70451194,0.1440271107,-1.750434504
14.32,19.71021431,0.1439973323,-1.751735189
14.33,19.71590787,0.1439675509,-1.753027008
14.34,19.72159253,0.1439383205,-1.754300729
14.35,19.7272684,0.1439101865,-1.755547371
14.36,19.73293507,0.1438815399,-1.756791968
14.37,19.73859289,0.1438540051,-1.758008473
14.38,19.74424174,0.1438270482,-1.759204919
14.39,19.74988131,0.1438001401,-1.760389092
14.4,19.7555118,0.1437738141,-1.761552154
14.41,19.76113319,0.1437480651,-1.762693636
14.42,19.76674549,0.1437228849,-1.76381309
14.43,19.7723483,0.1436977402,-1.764918068
14.44,19.77794203,0.1436731527,-1.766000085
14.45,19.78352724,0.1436496396,-1.767050836
14.46,19.78910229,0.1436256317,-1.768093181
14.47,19.79466909,0.1436026838,-1.769103209
14.48,19.80022735,0.1435802608,-1.770088225
14.49,19.80577665,0.1435578328,-1.771055225
14.5,19.81131812,0.1435359041,-1.77199618
14.51,19.81685229,0.1435144602,-1.772910716
14.52,19.82237975,0.1434934847,-1.77379851
14.53,19.82790017,0.1434724535,-1.77466639
14.54,19.83341483,0.1434518655,-1.775506685
14.55,19.8389241,0.1434317075,-1.776318986
14.56,19.84442836,0.1434119689,-1.777102964
14.57,19.84992801,0.1433926395,-1.777858389
14.58,19.85542347,0.1433737074,-1.7785851
14.59,19.86091412,0.1433546594,-1.77928953
14.6,19.86640147,0.1433359921,-1.779964959
14.61,19.87188604,0.1433176993,-1.780611436
14.62,19.87736839,0.1432997738,-1.781229002
14.63,19.88284798,0.1432817082,-1.781823856
14.64,19.8883265,0.1432639918,-1.782389761
14.65,19.8938046,0.1432466094,-1.782926854
14.66,19.89928299,0.1432295443,-1.783435308
14.67,19.90476252,0.1432127763,-1.783915291
14.68,19.91024405,0.1431962852,-1.784366963
14.69,19.91572729,0.143179556,-1.784796135
14.7,19.9212144,0.1431630619,-1.785197303
14.71,19.92670631,0.1431467836,-1.785570831
14.72,19.9322039,0.1431307035,-1.785917081
14.73,19.9377068,0.1431143112,-1.786241568
14.74,19.94321706,0.1430980799,-1.786539162
14.75,19.94873662,0.1430824821,-1.786805029
14.76,19.95426275,0.1430660215,-1.787054484
14.77,19.95979981,0.1430501527,-1.787272541
14.78,19.9653475,0.1430343631,-1.787464399
14.79,19.97090557,0.1430181421,-1.787634887
14.8,19.97647598,0.143001961,-1.787779225
14.81,19.98205956,0.1429857979,-1.78789754
14.82,19.98765712,0.1429696312,-1.787990038
14.83,19.9932684,0.1429529477,-1.788061261
14.84,19.99889492,0.1429362197,-1.788106796
14.85,20.00453794,0.1429199228,-1.788122514
14.86,20.01019509,0.1429025634,-1.788121079
14.87,20.01586899,0.1428856173,-1.788089895
14.88,20.02155882,0.1428685875,-1.788033213
14.89,20.02726388,0.1428509766,-1.787955006
14.9,20.03298497,0.1428332792,-1.787851362
14.91,20.03872215,0.1428154955,-1.787722472
14.92,20.04447551,0.1427976288,-1.78756858
14.93,20.05024468,0.1427791848,-1.78739348
14.94,20.05603039,0.1427606665,-1.787193777
14.95,20.06183318,0.142742582,-1.786966365
14.96,20.06765169,0.1427234456,-1.786721525
14.97,20.07348732,0.1427047608,-1.786449284
14.98,20.07933968,0.1426860355,-1.786153235
14.99,20.08520849,0.1426667767,-1.785836676
15,20.09109412,0.1426474917,-1.785496644
15.01,20.09699667,0.1426281916,-1.785133522
15.02,20.10291618,0.1426088889,-1.784747759
15.03,20.10885264,0.1425890933,-1.784342569
15.04,20.1148062,0.1425693168,-1.78391546
15.05,20.12077675,0.1425495723,-1.783466802
15.06,20.12676409,0.1425298737,-1.782996952
15.07,20.13276807,0.1425102348,-1.782506304
15.08,20.13878848,0.1424906705,-1.781995245
15.09,20.14482525,0.1424706932,-1.78146652
15.1,20.1508781,0.1424508199,-1.780917914
15.11,20.15694678,0.142431068,-1.780349678
15.12,20.16303091,0.142411455,-1.779762079
15.13,20.16913035,0.142391497,-1.779157501
15.14,20.17524437,0.1423717101,-1.778533884
15.15,20.18137255,0.1423521082,-1.777891417
15.16,20.18751449,0.142332706,-1.777230158
15.17,20.19366974,0.1423135173,-1.776550095
15.18,20.19983785,0.1422945534,-1.775851167
15.19,20.20601884,0.1422753302,-1.775134914
15.2,20.21221176,0.1422563584,-1.774399164
15.21,20.21841611,0.14223765,-1.773643669
15.22,20.22463142,0.1422192159,-1.772868201
15.23,20.23085789,0.1422005697,-1.772074032
15.24,20.23709458,0.1421822161,-1.771259204
15.25,20.24334047,0.1421646561,-1.770421995
15.26,20.24959691,0.1421464164,-1.769566315
15.27,20.25586146,0.1421289838,-1.768687676
15.28,20.26213413,0.1421118681,-1.767787182
15.29,20.26841499,0.1420945795,-1.766865634
15.3,20.27470288,0.1420776077,-1.765921435
15.31,20.28099731,0.1420609503,-1.764954252
15.32,20.2872978,0.1420446029,-1.763963709
15.33,20.29360459,0.1420280733,-1.762950252
15.34,20.29991653,0.142011845,-1.761912507
15.35,20.30623318,0.1419959113,-1.760850057
15.36,20.31255412,0.1419802651,-1.759762452
15.37,20.31887897,0.1419649022,-1.758649217
15.38,20.32520736,0.14194982,-1.757509945
15.39,20.33153957,0.141934535,-1.756344574
15.4,20.33787457,0.1419195308,-1.755152205
15.41,20.34421216,0.1419048063,-1.753932493
15.42,20.35055209,0.1418903597,-1.752685143
15.43,20.35689474,0.141875711,-1.751409818
15.44,20.36323913,0.1418613408,-1.750105968
15.45,20.36958432,0.1418477253,-1.748773374
15.46,20.37593204,0.1418334241,-1.747411125
15.47,20.38228009,0.1418198717,-1.746019421
15.48,20.38862882,0.1418065898,-1.744597697
15.49,20.3949786,0.1417931049,-1.743145046
15.5,20.40132854,0.1417798992,-1.741661466
15.51,20.40767834,0.1417669802,-1.740146461
15.52,20.41402771,0.141754354,-1.738599534
15.53,20.42037685,0.1417415509,-1.737019275
15.54,20.42672494,0.1417290491,-1.735406025
15.55,20.43307178,0.141716853,-1.73375945
15.56,20.43941728,0.1417049672,-1.732079264
15.57,20.44576139,0.1416933968,-1.730365211
15.58,20.45210399,0.1416821477,-1.728617057
15.59,20.4584454,0.1416707574,-1.72683301
15.6,20.46478493,0.1416597049,-1.725014336
15.61,20.47112244,0.141648997,-1.723160998
15.62,20.47745789,0.141638638,-1.721273049
15.63,20.48379162,0.141628165,-1.719348521
15.64,20.49012325,0.1416180493,-1.717389421
15.65,20.49645278,0.1416082949,-1.715395896
15.66,20.50278022,0.1415989084,-1.713368113
15.67,20.50910561,0.1415898987,-1.711306324
15.68,20.51542904,0.1415812751,-1.70921088
15.69,20.52175096,0.1415725855,-1.707079568
15.7,20.52807121,0.1415643005,-1.704915289
15.71,20.53439003,0.1415564277,-1.702718517
15.72,20.5407077,0.1415489755,-1.700489775
15.73,20.54702475,0.141541497,-1.698226616
15.74,20.55334121,0.1415344597,-1.695932585
15.75,20.55965717,0.1415283258,-1.693611636
15.76,20.56597344,0.141521743,-1.691254696
15.77,20.57228991,0.1415160763,-1.688872246
15.78,20.57860721,0.141510878,-1.68646182
15.79,20.58492589,0.1415057101,-1.684020437
15.8,20.59124607,0.1415010381,-1.681551958
15.81,20.59756804,0.1414968779,-1.679056772
15.82,20.60389207,0.1414932438,-1.67653524
15.83,20.61021844,0.1414897066,-1.673983747
15.84,20.61654715,0.1414867248,-1.671406351
15.85,20.62287826,0.1414847492,-1.668807456
15.86,20.62921215,0.1414824725,-1.666175214
15.87,20.63554844,0.1414812263,-1.663522207
15.88,20.64188709,0.1414805849,-1.660844709
15.89,20.64822791,0.141480135,-1.658138491
15.9,20.65457053,0.141480335,-1.655407885
15.91,20.66091471,0.1414812054,-1.652653038
15.92,20.66726029,0.1414827655,-1.649874203
15.93,20.67360725,0.1414846118,-1.647066988
15.94,20.67995554,0.1414871964,-1.644236138
15.95,20.68630503,0.1414909654,-1.641386613
15.96,20.6926556,0.1414946808,-1.638504094
15.97,20.69900689,0.1414996296,-1.635603077
15.98,20.70535872,0.1415054135,-1.632678862
15.99,20.7117109,0.1415116452,-1.629726565
16,20.71806333,0.1415187669,-1.626751425
16.01,20.72441597,0.1415268047,-1.623753775
16.02,20.73076876,0.1415357842,-1.620733899
16.03,20.73712147,0.1415453309,-1.617686632
16.04,20.74347384,0.1415558783,-1.61461741
16.05,20.74982555,0.1415674505,-1.611526486
16.06,20.75617636,0.1415800704,-1.608414129
16.07,20.76252592,0.1415937643,-1.605280559
16.08,20.76887391,0.1416085592,-1.602125966
16.09,20.7752198,0.1416241006,-1.598944812
16.1,20.78156338,0.1416408069,-1.595742921
16.11,20.78790438,0.1416587066,-1.592520532
16.12,20.79424255,0.1416778254,-1.589277915
16.13,20.80057744,0.1416978156,-1.586009331
16.14,20.80690914,0.1417190717,-1.582720864
16.15,20.81323775,0.1417419722,-1.579418916
16.16,20.81956218,0.141765448,-1.576085591
16.17,20.82588316,0.141790598,-1.572739618
16.18,20.83220029,0.1418170714,-1.56937537
16.19,20.83851307,0.1418445303,-1.565987
16.2,20.84482173,0.1418733353,-1.562581233
16.21,20.85112615,0.1419034922,-1.559158621
16.22,20.85742619,0.1419350085,-1.555719685
16.23,20.86372118,0.1419675617,-1.552258461
16.24,20.87001146,0.1420014981,-1.548781933
16.25,20.8762975,0.1420371451,-1.545297254
16.26,20.88257768,0.1420735338,-1.541785184
16.27,20.88885367,0.1421116345,-1.538265997
16.28,20.89512502,0.142151121,-1.534733619
16.29,20.90139104,0.1421916784,-1.531181794
16.3,20.90765218,0.1422336174,-1.527617416
16.31,20.91390826,0.1422769347,-1.524040692
16.32,20.92015913,0.1423216285,-1.520451909
16.33,20.92640388,0.142367401,-1.516844467
16.34,20.93264303,0.1424145457,-1.51322549
16.35,20.93887632,0.1424630544,-1.509595332
16.36,20.94510359,0.1425129168,-1.505954331
16.37,20.95132471,0.1425641225,-1.502302804
16.38,20.95753965,0.1426166606,-1.498641124
16.39,20.96374747,0.1426702489,-1.494962705
16.4,20.96994914,0.1427251525,-1.491275035
16.41,20.97614473,0.142781356,-1.48757865
16.42,20.98233427,0.1428388437,-1.483874137
16.43,20.98851669,0.1428973416,-1.480154956
16.44,20.99469303,0.1429570948,-1.47642881
16.45,21.0008645,0.1430183342,-1.472703563
16.46,21.00702776,0.1430802902,-1.46895838
16.47,21.01318632,0.1431436953,-1.465215447
16.48,21.01933906,0.1432082835,-1.461468105
16.49,21.02548464,0.1432738025,-1.457709556
16.5,21.03162421,0.1433404795,-1.453947424
16.51,21.0377578,0.1434082971,-1.450182193
16.52,21.04388552,0.1434772374,-1.446414421
16.53,21.0500061,0.1435470553,-1.442637468
16.54,21.05612108,0.1436179619,-1.438859291
16.55,21.06223071,0.143689936,-1.43508066
16.56,21.06833536,0.143762954,-1.431302383
16.57,21.07443545,0.1438369902,-1.427525258
16.58,21.08053143,0.1439120173,-1.423750048
16.59,21.08662214,0.1439877992,-1.419970279
16.6,21.09270945,0.1440645239,-1.416193932
16.61,21.09879372,0.1441421661,-1.412421846
16.62,21.10487521,0.1442207019,-1.408654822
16.63,21.11095257,0.1442999056,-1.404886567
16.64,21.11702762,0.1443799592,-1.401125187
16.65,21.12310233,0.1444610333,-1.397378708
16.66,21.12917214,0.1445425067,-1.393626787
16.67,21.13524226,0.1446249463,-1.389891533
16.68,21.14131129,0.1447081293,-1.386166645
16.69,21.14737767,0.1447918412,-1.382445829
16.7,21.15344319,0.1448762533,-1.378736751
16.71,21.15950799,0.1449613432,-1.375040105
16.72,21.16557227,0.1450470922,-1.3713566
16.73,21.17163445,0.1451332975,-1.36768009
16.74,21.17769646,0.1452201271,-1.364018081
16.75,21.18376025,0.145307743,-1.36037782
16.76,21.18982052,0.1453955808,-1.356739471
16.77,21.19588267,0.1454841716,-1.353123242
16.78,21.20194504,0.1455733155,-1.349522616
16.79,21.20800588,0.1456628187,-1.345931228
16.8,21.21406705,0.1457528464,-1.342355748
16.81,21.22012853,0.145843383,-1.338796212
16.82,21.2261903,0.1459344143,-1.335252607
16.83,21.23225038,0.1460257551,-1.331718626
16.84,21.23831054,0.1461175723,-1.328200634
16.85,21.24437072,0.1462098576,-1.324698652
16.86,21.25043084,0.1463026018,-1.321212696
16.87,21.25649078,0.1463957951,-1.317742796
16.88,21.26255036,0.1464894286,-1.314288977
16.89,21.26860746,0.1465833231,-1.310845253
16.9,21.2746638,0.1466776398,-1.307417756
16.91,21.28071921,0.1467723693,-1.304006518
16.92,21.28677349,0.1468675035,-1.300611467
16.93,21.29282445,0.1469628676,-1.297226785
16.94,21.29887396,0.147058621,-1.29385834
16.95,21.30492396,0.1471549194,-1.290511822
16.96,21.31096831,0.1472512527,-1.287170173
16.97,21.31701313,0.1473481041,-1.283850479
16.98,21.3230564,0.1474452958,-1.280547163
16.99,21.32909604,0.1475426552,-1.277254952
17,21.33513423,0.1476403404,-1.273979721
17.01,21.34117111,0.1477383465,-1.270721836
17.02,21.34720682,0.1478366683,-1.267481641
17.03,21.35323931,0.1479351379,-1.264254209
17.04,21.35927076,0.148033914,-1.261045267
17.05,21.36530123,0.1481329908,-1.257855116
17.06,21.37133078,0.1482323629,-1.254684013
17.07,21.37735943,0.1483320246,-1.251532157
17.08,21.38338714,0.1484319737,-1.248399586
17.09,21.38941162,0.1485320473,-1.245281368
17.1,21.39543506,0.1486324043,-1.242182545
17.11,21.4014575,0.1487330412,-1.239103141
17.12,21.40747894,0.1488339522,-1.236043219
17.13,21.41349705,0.1489349701,-1.232998191
17.14,21.41951402,0.1490362478,-1.229972913
17.15,21.42553217,0.1491379351,-1.226971978
17.16,21.43154444,0.149239541,-1.223981728
17.17,21.43755787,0.1493415312,-1.221015849
17.18,21.44357007,0.149443732,-1.218069771
17.19,21.44957861,0.1495459696,-1.215139191
17.2,21.45558586,0.149648391,-1.212228675
17.21,21.46159184,0.1497509874,-1.209338373
17.22,21.46759654,0.1498537527,-1.206468261
17.23,21.47359745,0.1499565209,-1.203614187
17.24,21.47959692,0.1500594451,-1.20078022
17.25,21.48559737,0.1501626802,-1.197970159
17.26,21.49159118,0.1502657399,-1.195171989
17.27,21.49758573,0.1503690985,-1.192397385
17.28,21.50357842,0.1504725871,-1.189642207
17.29,21.50956664,0.150576036,-1.186902542
17.3,21.51555284,0.1506796,-1.184181881
17.31,21.52153695,0.1507832731,-1.181479846
17.32,21.52751886,0.1508870499,-1.178796083
17.33,21.53349587,0.1509907624,-1.176126732
17.34,21.53947054,0.151094566,-1.173475038
17.35,21.54544285,0.1511984532,-1.170840642
17.36,21.55141282,0.151302416,-1.168223194
17.37,21.5573805,0.1514064452,-1.165622339
17.38,21.56334597,0.1515105315,-1.163037731
17.39,21.56930654,0.1516145034,-1.160465764
17.4,21.57526488,0.151718523,-1.15790898
17.41,21.58122088,0.1518225899,-1.155366546
17.42,21.58717446,0.1519267028,-1.152837621
17.43,21.59312266,0.1520306951,-1.150318327
17.44,21.5990681,0.1521347323,-1.147810926
17.45,21.6050135,0.1522389826,-1.145317553
17.46,21.61095017,0.1523429471,-1.142828583
17.47,21.61688661,0.1524471314,-1.140352216
17.48,21.62281992,0.1525513708,-1.137884816
17.49,21.62874707,0.152655499,-1.135422868
17.5,21.63467084,0.1527596878,-1.132968624
17.51,21.64059113,0.1528639402,-1.130521463
17.52,21.64650786,0.1529682595,-1.128080886
17.53,21.65241793,0.153072479,-1.125643695
17.54,21.65832424,0.1531767713,-1.123212265
17.55,21.66422976,0.1532813101,-1.12078889
17.56,21.67012519,0.1533855841,-1.11836492
17.57,21.6760196,0.1534901102,-1.115948054
17.58,21.68190977,0.1535947195,-1.113535079
17.59,21.68779241,0.1536992422,-1.111122866
17.6,21.69367054,0.1538038529,-1.108713652
17.61,21.69954408,0.1539085534,-1.106306965
17.62,21.70541295,0.1540133443,-1.103902321
17.63,21.71127379,0.1541180509,-1.101496592
17.64,21.71712975,0.1542228454,-1.099091949
17.65,21.72298405,0.1543278998,-1.096690541
17.66,21.7288267,0.1544326863,-1.094283965
17.67,21.73466747,0.1545377263,-1.091879582
17.68,21.74050285,0.1546428429,-1.089474177
17.69,21.74632917,0.1547478593,-1.087064428
17.7,21.75214953,0.1548529512,-1.084652231
17.71,21.75796372,0.1549581187,-1.082236847
17.72,21.76377153,0.155063361,-1.079817549
17.73,21.76956925,0.1551684993,-1.077390969
17.74,21.77536017,0.1552737105,-1.074959137
17.75,21.78114776,0.1553791763,-1.072524346
17.76,21.78692111,0.1554843651,-1.070078135
17.77,21.79269085,0.1555898184,-1.067628189
17.78,21.79845324,0.1556953636,-1.065171354
17.79,21.80420441,0.1558008257,-1.062704411
17.8,21.80994789,0.1559063889,-1.060229613
17.81,21.81568351,0.1560120589,-1.057746451
17.82,21.82141114,0.1561178412,-1.055254431
17.83,21.8271268,0.15622356,-1.05275022
17.84,21.83283411,0.1563293997,-1.050236182
17.85,21.83853292,0.1564353626,-1.04771188
17.86,21.84422314,0.1565414497,-1.045176857
17.87,21.84990471,0.156647661,-1.042630638
17.88,21.85557762,0.1567539956,-1.040072752
17.89,21.86123776,0.1568602699,-1.037499724
17.9,21.8668891,0.1569666634,-1.034914098
17.91,21.87253157,0.1570731726,-1.032315454
17.92,21.87816511,0.1571797947,-1.029703409
17.93,21.88378547,0.1572863435,-1.027074426
17.94,21.8893968,0.1573930012,-1.024431225
17.95,21.89500346,0.157499952,-1.021776684
17.96,21.9005927,0.157606645,-1.019100681
17.97,21.90617744,0.1577136364,-1.016412469
17.98,21.91175344,0.1578207457,-1.013708281
17.99,21.9173162,0.1579277911,-1.010984194
18,21.92287004,0.1580349611,-1.008243073
18.01,21.92841485,0.1581422597,-1.005484481
18.02,21.93395053,0.1582496951,-1.002708033
18.03,21.9394724,0.1583570923,-0.9999097804
18.04,21.94498487,0.1584646487,-0.9970929342
18.05,21.95048787,0.1585723784,-0.9942573576
18.06,21.95598136,0.1586802954,-0.991402948
18.07,21.96146528,0.1587884134,-0.988529598
18.08,21.96693959,0.1588967448,-0.9856371994
18.09,21.97239945,0.1590051138,-0.98272173
18.1,21.97784958,0.1591137146,-0.9797868753
18.11,21.98328998,0.1592225546,-0.9768325199
18.12,21.98872066,0.159331641,-0.9738585588
18.13,21.99413668,0.1594407951,-0.9708607751
18.14,21.99954293,0.1595502097,-0.9678430826
18.15,22.00494446,0.1596600775,-0.9648096281
18.16,22.01032623,0.1597698485,-0.9617476843
18.17,22.01570333,0.1598800865,-0.9586698382
18.18,22.02107075,0.1599906117,-0.9555717894
18.19,22.02642333,0.1601012444,-0.9524490193
18.2,22.03176624,0.1602121755,-0.9493057695
18.21,22.03709951,0.1603234096,-0.946141963
18.22,22.04242318,0.1604349509,-0.9429575247
18.23,22.04773193,0.160546619,-0.9397477306
18.24,22.05303102,0.160658602,-0.9365170545
18.25,22.05832586,0.1607710864,-0.9332701933
18.26,22.06360022,0.160883524,-0.9299928111
18.27,22.06887029,0.1609964673,-0.9266991472
18.28,22.07413065,0.1611097351,-0.9233843959
18.29,22.07937579,0.1612231481,-0.9200435533
18.3,22.08461124,0.1613368923,-0.9166814763
18.31,22.08983706,0.1614509705,-0.9132981536
18.32,22.09505325,0.161565385,-0.9098935931
18.33,22.10025417,0.1616799586,-0.9064626558
18.34,22.1054455,0.1617948775,-0.9030105169
18.35,22.11062731,0.1619101465,-0.899537262
18.37,22.12096271,0.1621417535,-0.892527764
18.38,22.12611643,0.1622580999,-0.8889917068
18.39,22.131255,0.1623746351,-0.8854293738
18.4,22.13638432,0.1624915436,-0.8818462918
18.41,22.14150449,0.1626088307,-0.878242563
18.42,22.14661561,0.1627265016,-0.8746182937
18.43,22.15171171,0.1628443834,-0.8709678393
18.45,22.16188335,0.163081508,-0.863611604
18.46,22.1669468,0.1632004055,-0.8598943439
18.48,22.17705994,0.1634397721,-0.8524114261
18.49,22.18209737,0.163559897,-0.8486341078
18.51,22.19214665,0.1638013974,-0.8410202066
18.55,22.21212558,0.1642889599,-0.8255397316
18.56,22.21709813,0.1644118812,-0.8216198964`
