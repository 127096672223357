// Dummy schema for title/body/image etc. element.
export default () => ({
  schema: {
    type: 'string',
  },
  uiSchema: {
    'ui:options': {
      dummy: true,
    },
  },
});
